@use '../_colors.scss';
@use '../_device-mixins.scss';
@use '../global_vars.scss';

.container {
    position: relative;
}

.skeleton_card_base {
    height: 250px;
    width: 250px;
    border-radius: 21px;
    margin: 10px;
    position: relative;

    @include device-mixins.lg {
        height: 320px;
        width: 320px;
    }
}

.activity_card_icon_container {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    > svg {
        height: 100%;
        width: auto;
    }
}

.form_control_label {
    margin: 0;
    position: relative;
    display: inline;
}

.selected {
    &::before {
        content: '';
        border: 5px solid colors.$bluePurple;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 21px;
        z-index: 5;

        @include device-mixins.lg {
            border-width: 8px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        border-top: 85px solid colors.$bluePurple;
        border-right: 85px solid transparent;
        top: 0;
        left: 0;
        transition: opacity 0.1s ease-in;
    }
}

.premium_container {
    height: 100%;
    position: relative;
}

.premium_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid colors.$lightOrange;
    border-radius: 24px;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    &::before {
        content: 'Premium Activity';
        width: 125px;
        height: 18px;
        padding: 12px 0px 12px 35px;
        border-radius: 0px 21px;
        border: 1px solid colors.$lightOrange;
        background: colors.$white;
        color: colors.$bluePurple;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        top: 0;
        right: 0;
        position: absolute;
        background-image: url('../../components/icons/svgs/Stars.svg');
        background-size: auto; /* Ensure the icon fits within the dimensions */
        background-repeat: no-repeat;
        background-position: 8%; /* Center the background image */
    }
}

.checkmark {
    display: none;
    color: colors.$white;
}

.checkmark_selected {
    display: block;
    position: absolute;
    left: 13px;
    top: 12px;
    z-index: 2;
}

.radio_input {
    opacity: 0;
    position: absolute;
}

.activity_card_header {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: colors.$black;
    margin: 0;

    @include device-mixins.sm {
        font-size: 1.7rem;
    }
}

.all_in_bg {
    background-color: #b588ee;
}

.pair_it_bg {
    background-color: #ffd700;

    & > svg {
        height: 65%;
        width: auto;
    }
}

.battle_royale_bg {
    background-color: #e67956;
}

.quick_write_bg {
    background-color: #6ce656;
}

.partner_up_bg {
    background-color: #006fff;
    & > svg {
        height: 60%;
        width: auto;
    }
}

.activity_card_description {
    font-size: 0.9rem;
    color: colors.$black;
    margin: 0;
    padding: 0 5px;

    @include device-mixins.lg {
        padding: 5px 15px;
        font-size: 1.1rem;
    }
}

.coming_soon_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: colors.$bluePurple;
    opacity: 0.95;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    &::after {
        content: 'Coming Soon!';
        color: colors.$white;
        font-size: 36px;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: 0.15px;
        padding: 1rem;
        opacity: 1;
        text-align: center;
    }
}

.ai_link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    a {
        color: colors.$bluePurple;
        text-decoration-line: underline;
    }
}

.beta_badge {
    position: absolute;
    top: 5px;
    right: -20px;
    border-radius: 5px;
    background: colors.$seafoam;
    z-index: 100;
    display: inline-flex;
    transform: rotate(7.856deg);
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 75% */
    letter-spacing: 0.15px;
}