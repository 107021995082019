$blue: #2C71E4;
$darkBlue: #222362;
$bluePurple: #2326C9;
$darkBluePurple: #2629AA;
$lightBluePurple: #E9EAFF;
$periwinkle: #8C8CFF;
$lightBlue: #95B8F1;
$premiumBlue: #4466FF;
$turqoise: #97FFED;
$slate: #31495A;
$green: #1E9A3F;
$lightGreen: #BCE1C5;
$seafoam: #00BD9D;
$darkGreen: #00725E;
$tanGray: #D7CDCC;
$darkGray: #6a6665;
$lightGray: #CCCCD7;
$lightTanGray: #F1F4F7;
$lightBlueGray: #F9F9FF;
$lightMetalGray: #E3E4E6;
$gray: #7C7F8A;
$silver: #F3F3F3;
$ghostWhite: #F8F9FA;
$white: #FFFFFF;
$black: #363232;
$red: #DA493D;
$darkRed: #981b1e;
$gold: #F0C436;
$lightYellow: #F5DA8C;
$mustard: #EBB51A;
$ruby: #D81E5B;
$darkRuby: #AB1848;
$bronze: #E9AD74;
$burntOrange: #D06746;
$orange: #FF8023;
$lightRed: #E3766D;
$lightOrange: #FFBF91;
$magenta: #CD46D0;

// New Discover page colors
$slimyGreen: #388C03;
$fireRed: #C92323;
$ochre: #C97923;
$viridianGreen: #0C9A8C;
$navyBlue: #237EC9;
$oceanBlue: #5A23C9;
$royalFuchsia: #C9238C;
