@use '../_colors.scss';
@use '../device-mixins';

.form_container {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
}

.heading {
    padding-bottom: 5px;
    color: colors.$black;
    background-color: transparent;

    span {
        font-size: 16px;
        font-weight: normal;
    }
}

.dark_mode {
    color: colors.$white;
}

.bold_subjects {
    font-weight: bold;
}

.form_control {
    width: 100%;
}

.autocomplete {
    overflow: hidden;
    padding: 0;
}

.autocomplete :global .MuiInputBase-root {
    background-color: colors.$white;
    margin-bottom: 20px;
    border-radius: 4px;
}

.autocomplete :global .MuiFilledInput-root {
    padding-top: 0px;
}