@use '../colors';

.modal_contents {
    max-width: 700px;
    padding: 30px;

    h2 {
        margin: 0 0 30px;
        color: colors.$black;
        font-size: 32px;
        font-weight: 700;

        strong {
            color: colors.$bluePurple;
        }
    }

    p {
        font-size: 24px;
        font-weight: 500;
    }
}

.button_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    button {
        margin-left: 20px;
        padding: 5px 25px;
        font-size: 20px;
        font-weight: 600;
    }

    .text {
        color: colors.$bluePurple;
    }

    .link_secondary {
        color: colors.$bluePurple;
        border: 1px solid colors.$bluePurple;
    }

    .link_primary {
        display: flex;
        align-items: center;
        color: colors.$white;
        background-color: colors.$bluePurple;
        border-radius: 4px;
        margin-left: 20px;
        padding: 5px 25px;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none;
    }
}
