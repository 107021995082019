@use '../_colors.scss';

.container {
    width: 100%;
    margin-top: 5px;

    & > div {
        background-color: colors.$white;
        border-radius: 5px;
    }
}

.checklist_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    margin-top: 5px;

    & > div {
        background-color: colors.$white;
        border-radius: 5px;
    }
}

.form_container { 
    display: flex;
    width: 100%;
    justify-content: center;
}

.checkbox_container {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    grid-template-rows: repeat(6, auto);
    grid-gap: 5px;
}

.checkbox_container > label:last-child {
    grid-column: 1 / -1; /* Span across all columns */
    justify-self: center; /* Center the text */
}

.dropdown_label {
    font-weight: bold;
    padding-bottom: 5px;
    color: colors.$white;
    background-color: transparent;

    span {
        font-size: 16px;
        font-weight: normal;
    }
}


.placeholder_text {
    color: colors.$gray;
}