@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    overflow: auto;
    height: 100%;
    background-color: colors.$bluePurple;
}

.logo_text_link_link {
    margin: 3% 0;

    @include device-mixins.lg {
        justify-content: flex-start;
        margin: 1% 0 0 1%;
    }
}

.card {
    background-color: colors.$white;
    width: 90%;
    border-radius: 10px;
    padding: 3%;
    display: flex;
    flex-direction: column;
    margin: auto;

    @include device-mixins.lg {
        width: 40%;
        margin: 3% 3% 0 auto;
    }
}

.h1 {
    font-size: 2rem;
    font-weight: 700;
    color: colors.$darkBlue;
    margin-top: 3%;
    margin-bottom: 0;
    text-align: center;

    @include device-mixins.sm {
        margin-top: 0;
        font-size: 3rem;
    }
}

.caption {
    font-size: 1.2rem;
    font-weight: 400;
    color: colors.$darkBlue;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    margin-top: 3%;
}

.google_button_container {
    margin-top: 25px;

    @include device-mixins.md {
        margin-top: 3%;
    }
}

.forgot_password_link {
    color: colors.$darkBlue;
    margin-bottom: 3%;
    margin-top: 5px;
}

.error_text {
    font-size: 1rem;
    text-align: center;
}

.divider {
    color: colors.$gray;
    margin: 3% 0 1% 0;

    &::before {
        border-color: colors.$gray;
    }

    &::after {
        border-color: colors.$gray;
    }
}

.caption_link_container {
    margin-top: 10%;
    align-self: center
}

.caption_link {
    @extend .caption;

    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 1.2rem;
    margin-bottom: 10%;

    & > a {
        color: colors.$ruby;
        margin-left: 8px;
    }
}
