@use '../../../colors';
@use '../../../device-mixins';

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1% 2% 6%;
}

.h1 {
    color: colors.$darkBlue;
    font-size: 1.5rem;
    margin: 1% 0;

    @include device-mixins.sm {
        font-size: 2.5rem;
    }
}

.h2 {
    color: colors.$darkBlue;
    font-size: 1rem;
    font-weight: 500;
    margin: 1% 0;

    @include device-mixins.sm {
        font-size: 1.3rem;
    }
}

.input_container {
    display: flex;
    justify-content: space-evenly;
}

.input {
    flex: 1;
    margin: 2% 2% 0;

    @include device-mixins.md {
        margin: 1% 1% 0;
    }
}

.input_label {
    font-size: 1.2rem;
}

.button_container {
    text-align: right;
    margin: 2%;

    @include device-mixins.sm {
        margin: 1%;
    }
}

.error_text {
    font-size: 1rem;
}

.button {
    margin: auto;
}
