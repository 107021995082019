@use '../colors';
@use '../device-mixins';

.container {
    width: 100%;
    margin: 1rem auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.skeleton_container {
    display: flex;
    flex-direction: column;
}

.skeleton {
    height: 70px;
    width: 100%;
    margin: 1rem auto auto;
}

.table {
    border: none;
}

.table_header {
    font-size: 20px;
    font-weight: 700;
    border-top: none;
    border-right: none;
    border-left: none;
    color: colors.$darkBlue;
    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

.topic_cell {
    display: flex;
    align-items: center;
}

.no_questions {
    padding: .5rem 0;
    border-bottom: none;
}

.no_questions_msg {
    font-size: 1rem;
    font-style: italic;
}
