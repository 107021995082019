@use '../colors.scss';
@use '../device-mixins';
@use '../pages/dashboard/account/AccountSettings.scss';

.delete_account_container {
    padding-bottom: 2%;
}

.header {
    display: flex;
    align-items: center;
}

.warning_icon {
    color: colors.$gold;
    margin-right: 10px;
    margin-bottom: 2px;
}

.description_container {
    text-align: center;
    padding: 5%;

    @include device-mixins.sm {
        padding: 2% 0;
    }
}

.description {
    font-size: 1rem;

    @include device-mixins.sm {
        margin: 2%;
        font-size: 1.2rem;
    }
}

.form {
    display: flex;
    flex-direction: column;
}

.p {
    margin: 0;
}

.list {
    list-style-type: disc;
    list-style-position: inside;
}

.list_item {
    display: list-item;
}

.delete_account_modal_header {
    color: colors.$black;
    font-weight: 500;
    font-size: 1rem !important;
}

.delete_account_input {
    margin: 2% 0;
    flex: 1;
}

.delete_account_input :global .MuiFormLabel-root.Mui-focused {
    color: colors.$black;
}

.delete_account_input :global .MuiOutlinedInput-notchedOutline {
    border-color: colors.$black !important;
}

.delete_account_button_container {
    margin: 3% auto;
}

.delete_account_button {
    margin-right: 10px;
}
