@use '../../colors';
@use '../../device-mixins';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 3%;
    justify-content: center;
}

.h1 {
    font-size: 1.4rem;
    color: colors.$black;
    font-weight: 500;
    text-align: center;
}

.button_container {
    display: flex;
    flex-direction: column;
}

.text_button {
    margin-top: 10px;
}
