@use '../../colors';
@use '../../device-mixins';

.container {
    padding: 20px;
}

.dialog_title {
    color: colors.$black;
    font-size: 1.5rem;
    font-weight: 600;
}

.subtitle {
    color: colors.$black;
    font-size: 1.2rem;
    font-weight: 400;
    padding-top: 0;
    padding-bottom: 0;
}

.dialog_content {
    color: colors.$black;
}

.button {
    font-size: 1.2rem;
}

.header_container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
}
