@use '../_colors.scss';
@use '../_device-mixins.scss';

.button_base {
    padding: 5px 10px;
    font-size: 1.1rem;
    min-width: 100px;
    text-transform: none;
    font-weight: 500;
    white-space: nowrap;
}

.primary {
    background-color: colors.$bluePurple;

    &:hover {
        background-color: colors.$darkBluePurple;
    }
}

.secondary {
    color: colors.$bluePurple;
    background-color: colors.$lightBluePurple;
    border: none;

    &:hover {
        color: colors.$darkBluePurple;
        background-color: colors.$lightBluePurple;
        border: none;
    }
}

.tertiary {
    background-color: colors.$darkGreen;

    &:hover {
        background-color: colors.$darkGreen;
    }
}

.danger {
    color: colors.$ruby;
    border: 1px solid colors.$ruby;

    &:hover {
        color: colors.$ruby;
        background-color: transparent;
        border: 1px solid colors.$ruby;
    }
}

.text {
    color: colors.$black;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.loader {
    color: colors.$white;
}

.loader_blue {
    color: colors.$darkBlue;
}
