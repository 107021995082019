@use '../../colors';
@use '../../device-mixins';

.dialog :global .MuiDialog-paper {
    position: inherit;
    max-width: none;
}

.hide_overflow :global .MuiDialog-paper {
    overflow: hidden;
}

.backdrop {
    background-color: rgba(0, 0, 0, 0.8);
}

.iframe_container {
    border: none;
    padding: 0;
    height: 80vh;
    width: 72vw;
    
    @include device-mixins.md {
        width: 80vw;
    }
}

.iframe {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    zoom: 1;
}

.button {
    position: fixed;
    top: 50%;
    padding: 1rem;
    
    @include device-mixins.md {
        background-color: colors.$black;
        &:hover {
            background-color: #454545;
        }
        
        &:focus {
            outline: 5px solid -webkit-focus-ring-color;
            outline: 1px solid Highlight;
        }
    }
}

.left {
    left: 1%;
    transform: rotate(90deg);

    @include device-mixins.md {
        left: 5%;
    }
}

.right {
    right: 1%;
    transform: rotate(270deg);

    @include device-mixins.md {
        right: 5%;
    }
}

.chevron {
    color: colors.$black;
    font-size: 1.5rem;

    @include device-mixins.md {
        color: colors.$white;
    }
}

.external_link {
    color: colors.$white;
}

.external_link_button {
    @extend .button;

    top: 5%;
    right: 1%;

    @include device-mixins.md {
        right: 5%;
    }
}