@use '../_colors.scss';
@use '../_device-mixins.scss';

.column {
    font-size: 20px;
    font-weight: 500;

    &:last-child {
        padding-right: 1rem;
    }
}

.question_column_collapsed {
    width: 60%;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: colors.$black;
}

.question_column_expanded {
    width: 60%;
    max-width: 100px;
    color: colors.$black;
}

.topic_column_expanded {
    width: 15%;
    max-width: 40px;
    white-space: break-word;
}

.topic_column_collapsed {
    width: 15%;
    white-space: nowrap;
    max-width: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.updated_column {
    width: 18%;
    white-space: nowrap;
}

.launch_column {
    width: 10%;
}

.launch_button {
    flex-shrink: 0;
    margin-right: 10%;
    color: colors.$darkBluePurple;
    width: min-content;
    border: 1px solid colors.$bluePurple;

    &:hover {
        background-color: colors.$darkBluePurple;
        color: colors.$white;
        transition: all 0.1s ease-in;        
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    @include device-mixins.md {
        margin-right: 3%;
    }
}

.launch_icon {
    rotate: 45deg;
}

.vertical_three_dot {
    & > svg {
        color: colors.$gray;
    }
}

.menu_list {
    padding: 0;
    
    svg {
        font-size: 1.1rem;
    }
}

.menu_item {
    text-decoration: none;
}

.menu_item_icon {
    color: colors.$black;
}

.menu_item_text {
    & > span {
        font-size: 1.1rem;
    }
}

.checkmark_icon {
    background-color: colors.$darkBluePurple;
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 35px;
    path {
        stroke: colors.$white;
    }
}

.no_questions {
    padding: 0.5rem 0;
    border-bottom: none;
}

.no_questions_msg {
    font-size: 1rem;
    font-style: italic;
}

.add_all_container {
    margin: 55px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h3 {
    margin-top: 0;
    color: colors.$black;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
}

.feedback_criteria_container {
    display: flex;
}

.expanded_content_container {
    display: flex;
    justify-content: flex-start;
    gap: 10rem;
    padding: 1.5rem 0 0 0;
}

.row {
    cursor: pointer;

    &:hover {
        background-color: colors.$lightBluePurple;
    }
}

.hidden_column {
    padding-top: 0;
    padding-bottom: 0;
}

.delete_question_modal_content {
    max-width: 500px;
    font-size: 1.2rem;
}

.warning_icon {
    color: colors.$gold;
    font-size: 2rem;
    margin-right: -10px;
    margin-left: 10px;
}

.feedback_criteria_container {
    display: flex;
    gap: 1rem;
    max-width: 550px;
    flex-wrap: wrap;
}