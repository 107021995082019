@use '../_colors.scss';
@use '../_device-mixins.scss';

.chevron_btn {
    grid-row: 4;

    width: 107px;
    height: 27px;
    padding: 0;
    z-index: 1;
    border-radius: 12px;
    margin-bottom: -13.5px;

    background-color: white;
    color: #7C7F8A;
    border: none;
    box-shadow: 0px 0px 4.8px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;

    @include device-mixins.md {
        grid-row: 3;
        grid-column: 2;
    }
}

.clipboard {
    font-size: 1.7rem;
    color: colors.$black;
}

.clipboard_active {
    color: colors.$darkGreen;
}

.collapse {
    grid-row: 2;
    grid-column: 2 span;

    @include device-mixins.md {
        grid-column: 2;
    }
}

.criteria_container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    & > span {
        margin-bottom: 1rem;
    }
}

.collapse_content_container {
    display: flex;
    flex-direction: column;

    @include device-mixins.md {
        flex-direction: row;
    }
}

.file_attachment {
    margin-right: 20px;
    margin-bottom: 10px;

    & div {
        width: 100%;
    }

    & p {
        width: 50%;
        overflow: hidden;
    }
}

.file_attachment_container {
    display: flex;
    flex-wrap: wrap;

    @include device-mixins.md {
        width: fit-content;
    }

    & > span {
        margin-right: 1%;
    }
    
    @include device-mixins.sm {
        flex-wrap: nowrap;
    }
}

.container {
    padding: 2.5% 5% 0 5%;

    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.04);
    position: relative;
    z-index: 1;

    flex: 0 1 auto;
    background-color: colors.$white;

    grid-template-rows: auto auto 1fr;

    @include device-mixins.md {
        padding: 0.5% 1% 0 1%;
        grid-template-columns: 60px 1fr 60px 52.5px;
        grid-template-rows: unset;
    }
}

.question_text {
    font-weight: 500;
    font-size: 1rem;
    color: colors.$black;
    margin: 10px 0;
    grid-column: 2 span;
    max-height: 300px;
    overflow: auto;
    padding-right: 10px;
    
    @include device-mixins.md {
        grid-column: 2;
        font-size: 1.5rem;
    }
}

.see_less_chevron {
    transform: rotate(180deg);
    font-size: x-small;
}

.see_more_chevron {
    font-size: x-small;
}

.threedot {
    align-content: baseline;
    text-align: right;
    margin-top: 8px;
    grid-column: 3;
    
    button {
        color: #8A8C95;
    }

    @include device-mixins.md {
        margin-right: -8px;
    }
}
