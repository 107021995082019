@use '../_colors.scss';

.backdrop {
    background-color: colors.$bluePurple;
    opacity: 0.85 !important;
}

.container {
    padding: 0;
}

.container :global .MuiPaper-root {
    max-width: none;
    border-radius: 24px;

}

.close_button {
    color: colors.$black;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1200;
}

.close_icon {
    font-size: 1.5rem;
}