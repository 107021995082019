@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    height: fit-content;
}

.p {
    font-weight: 500;
    color: colors.$black;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1rem;
}

.icon {
    align-self: center;
    font-size: 20px;
    margin-right: 5px;
}

.variable_color {
    --var-color: #{colors.$black};

    path {
        stroke: var(--var-color);
        fill: var(--var-color);
    }
}
