@use '../../../_colors.scss';
@use '../../../_device-mixins.scss';
@use '../../../global_vars';

.container {
    display: flex;
    padding: 6% 2% 0;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include device-mixins.md {
        padding: 1% 2% 0;
    }
}

.icon_loader {
    height: calc(90% - global_vars.$footer_height);
}

.h1 {
    align-self: flex-end;
    color: colors.$darkBlue;
    font-size: 2.5rem;
    margin: 0 1%;
    white-space: nowrap;
}

.header_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.max_question_alert {
    padding: 0 5px;
}

.search_and_button_container {
    display: flex;
    justify-content: flex-end;
    width: 65%;
    align-self: center;
}

.alert_container {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    width: 100%;
}

.search_bar {
    display: none;
    width: 15%;
    min-width: 150px;
    transition: width 350ms ease-in-out;
    &:focus-within {
        width: 50%;
    }

    @include device-mixins.md {
        display: block;
    }
}

.cta_button {
    margin-left: 1%;
}

.add_button {
    margin-left: 1%;
    padding: 0 3%;
    font-weight: 700;
}

.button_link {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;

    &:hover,
    &:focus {
        color: colors.$darkRed;
        text-decoration: underline;
        outline: none;
    }

    &:focus {
        outline: none;
    }

    // Maintain a focus style for keyboard users (for accessibility)
    &:focus-visible {
        outline: auto 1px blue;
    }

    // Hide the button appearance on iOS devices
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.tabs_container {
    width: 100%;
    margin-top: 2.5rem;
}

.tabs {
    max-width: 100%;
    border-bottom: 1px solid colors.$lightGray;

    :global .MuiTabs-indicator {
        background-color: colors.$darkBluePurple;
    }

    :global .MuiTabScrollButton-horizontal {
        color: colors.$darkGray;
    }

    :global .Mui-disabled {
        opacity: 0;
        display: none;
    }

    :global .Mui-selected {
        color: colors.$darkBluePurple;
        font-weight: 700;
        background-color: colors.$lightBluePurple;

        &:not(:first-child) {
            padding-right: 0;
        }
    }
}

.tab_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 20px;
}

.topic_menu_three_dot {
    color: colors.$darkBluePurple;
    margin-left: 10px;

    svg {
        font-size: 12px;
    }
}

.tab {
    font-size: 16px;
    font-weight: 600;
}

.send_link_wrapper {
    position: sticky;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    background-color: colors.$lightBlueGray;
}

.send_link {
    margin-bottom: 5px;
}

.send_icon_size {
    font-size: 16px;
    margin-right: 8px;
}

.rename_topic_input {
    width: 500px;
    max-width: 100%;
}