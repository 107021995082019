@use '../_colors.scss';
@use '../_device-mixins.scss';

@keyframes bounce {
    0%, 28.57%, 100% {
        transform: translateY(0);
    }
    14.29% {
        transform: translateY(-6px);
    }
}

.dot {
    display: inline-block;
    animation: bounce 2.5s cubic-bezier(0.215, 0.610, 0.355, 1) infinite;
}

#dot1 {
    animation-delay: 0s;
}

#dot2 {
    animation-delay: 0.2s;
}

#dot3 {
    animation-delay: 0.3s;
}