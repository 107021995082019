@use '../_colors.scss';
@use '../_device-mixins.scss';

.link {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    height: fit-content;
    width: 200px;
    color: colors.$white;
    fill: none;
}
