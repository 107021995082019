@use '../_colors.scss';
@use '../_device-mixins.scss';

.notFound_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: colors.$ghostWhite;
    text-align: center;
    color: colors.$black;

    h1,
    h2,
    h3 {
        color: colors.$black;
        margin: 0;
    }

    h1 {
        display: flex;
        justify-content: center;
        font-size: 10rem;
        font-weight: 600;
        line-height: 11rem;
        color: colors.$red;

        @include device-mixins.md {
            font-size: 18rem;
            line-height: 19rem;
        }

        svg {
            max-width: 180px;
            max-height: 180px;

            @include device-mixins.md {
                max-width: 300px;
                max-height: 300px;
            }
        }
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 2rem;

        @include device-mixins.md {
            font-size: 2rem;
        }
    }

    h3 {
        font-size: 1.2rem;
        line-height: 1.7rem;
        font-weight: 500;

        @include device-mixins.md {
            font-size: 2rem;
            line-height: 2rem;
        }
    }

    .home_button {
        margin-top: 5rem;
    }
}
