@use '../../colors';
@use '../../device-mixins';

.content_header {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 20px;
    align-items: center;
}

.content_header_name {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
    display: flex;
    padding-left: 0;
    align-items: flex-end;
}

.icon {
    margin-right: 8px;
}

.display_name {
    margin: 0;
}

.translate_icon {
    @extend .translate_hover;

    svg {
        font-size: 1.5rem;
        margin-right: 5px;
    }
}

.translate_color {
    color: colors.$magenta !important;

    & svg {
        color: colors.$magenta !important;
    }
}

.translate_hover {
    &:hover {
        @extend .translate_color;
        background-color: colors.$lightGray;
        font-weight: 500;
        transition: font-weight 0.1s ease;
    }
}

.language_dropdowns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    
    & > div:not(.spacer) {
        flex: 1;
    }

    @include device-mixins.md {
        flex-direction: row;
    }
}

.output_container {
    border-radius: 0px 6px 6px 0px;
    border-left: 2px solid transparent;
    max-height: calc(100% - 134px);
    overflow-y: auto;

    &:hover {
        border-left: 2px solid colors.$magenta;
        background-color: #fde8fa;

        & > div.translate_footer {
            background-color: #fde8fa;
            border-radius: 0px 0px 6px 0px;
        }
    }
}

.translation {
    padding: 1rem;
}

.translate_footer {
    display: flex;
    flex-direction: row;
    position: sticky;
    bottom: 0px;
    justify-content: space-between;
    padding: 1rem;
    background-color: #FFFFFF;
    border-left: 2px solid transparent;
}

.translate_prompt {
    color: colors.$black;
    opacity: 0.5 !important;
}
