@use 'styles/_colors.scss';
@use 'styles/_device-mixins.scss';

.learn_more_container {
    display: flex;
    justify-content: space-between;
    background-color: colors.$white;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;

    .brain_icon {
        font-size: 64px;
    }

    h4 {
        margin: 0 0 5px 0;
    }

    a {
        color: colors.$bluePurple;
        text-decoration-line: underline;
        font-weight: 600;
    }
}

.tabs {
    border-bottom: 1px solid colors.$lightGray;

    :global .Mui-selected {
        color: colors.$darkBluePurple;
        font-weight: 600;
    }

    :global(.MuiTabs-indicator) {
        height: 2px;
    }
}

.tab {
    color: colors.$black;
    font-size: 16px;
    font-weight: 700;
    padding: 0 20px;
}

.criteria_list {
    margin: 20px 0;
    height: 330px;
    overflow: auto;
}

.criteria_list_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    gap: 16px 20px;
    width: 100%;
}

.slected_criteria_container {
    position: relative;
    background-color: colors.$lightBluePurple;
    padding: 24px 20px;

    h5 {
        margin: 0;
        color: colors.$bluePurple;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.15px;
    }
}

.feedback_list {
    margin: 10px 0 0;
}

.feedback_opt {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 5px;
    border-radius: 5px;
    color: colors.$black;
    font-size: 16px;
    font-weight: 700;

    &:hover {
        background-color: colors.$silver;
    }

    :global(.MuiIconButton-root) {
        padding: 5px;
        margin-left: 5px;
    }

    .close_icon {
        font-size: 1rem;
        stroke: colors.$black;
        stroke-width: 1.5px;
    }
}

.header_container {
    display: flex;
    justify-content: space-between;

    h5 {
        min-width: 55%;
    }
}

.max_criteria_alert {
    padding: 0 5px;
    width: fit-content;
    margin: 10px auto 0;

    @include device-mixins.md {
        margin: 0 10px;
    }

    .button_link {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: bold;

        &:hover,
        &:focus {
            color: colors.$darkRed;
            text-decoration: underline;
            outline: none;
        }

        // Maintain a focus style for keyboard users (for accessibility)
        &:focus-visible {
            outline: auto 1px blue;
        }

        // Hide the button appearance on iOS devices
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}
