@use '../_colors.scss';
@use '../_device-mixins.scss';

.dropzone {
    position: relative;
    width: 100%;
    height: 100%;
}

.container {
    position: relative;
    border: 1px colors.$black dashed;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 70px 10px 50px 10px;
    font-size: 20px;
    font-weight: 500;
    background-color: colors.$lightTanGray;

    svg {
        font-size: 3.5em;
        margin-bottom: 15px;
    }
}

.link_text {
    display: inline;
    border: none;
    border-radius: 0;
    background-color: none;
    margin: 0;
    padding: 0;
    line-height: 1.2em;
    border-bottom: 2px colors.$black solid;
    font-size: 20px;
    font-weight: 500;

    &:hover {
        background-color: colors.$lightTanGray;
    }
}

.max_file_count {
    color: colors.$lightGray;
    svg path {
        fill: colors.$lightGray;
    }

    .link_text {
        border-bottom: 2px colors.$lightGray solid;
        color: colors.$lightGray;
    }
}

.overlay {
    position: absolute;
    background-color: colors.$lightBluePurple;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.5;
}

.file_attachment_container {
    position: absolute;
    bottom: 10px;
    padding-left: 15px;

    display: flex;
    flex-wrap: wrap;
    width: fit-content;

    & > span {
        margin-right: 1%;
    }

    @include device-mixins.sm {
        flex-wrap: nowrap;
    }
}

.file_attachment {
    margin-bottom: 10px;

    & div {
        width: 100%;
    }

    & p {
        width: 50%;
        overflow: hidden;
    }

    @include device-mixins.sm {
        margin-right: 20px;
    }
}

.dropzone_notes {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
}
