@use 'styles/_colors.scss';

.hidden {
    display: none !important;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: colors.$bluePurple;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    z-index: 100;
}

.content_wrapper {
    .bulb {
        svg {
            width: initial;
            height: initial;
        }
    }
    
    .h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 120%;
        color: colors.$white;
        margin: 45px 0;
        
        div {
            display: inline-block;
        }

        .h1_word {
            color: colors.$seafoam;
        }
    }
}

