@use '../_colors.scss';
@use '../_device-mixins.scss';

.dialog_background :global .MuiPaper-root {
    background-image: url('../../../client/components/icons/svgs/BlobBackdrop.svg');
    background-repeat: no-repeat;
    height: 614px;
    width: 993px;
}

.dialog_close_button {
    color: colors.$white;
}

.dialog_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h1 {
    color: colors.$white;
    margin-bottom: 0;
    font-size: 2.3rem;

    @include device-mixins.md {
        font-size: 4rem;
    }
}

.h2 {
    color: colors.$gold;
    margin-top: 0;
    font-size: 1.3rem;
}

.italic {
    font-style: italic;
}

.iframe_container {
    width: 100%;
    height: 100%;

    @include device-mixins.sm {
        width: 65%;
        height: 60%;
    }
}

.iframe {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}
