@use '../../_colors.scss';
@use '../../_device-mixins';


.menu_list {
    padding-bottom: 0 !important;
}

.header_container {
    display: grid;
    grid-template-columns: 45px auto;
    grid-template-rows: auto auto auto;
    row-gap: 20px;
    column-gap: 5px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.avatar {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
}

.name {
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 700;
    padding-right: 10px;
}

.code_name {
    font-style: italic;
    font-size: 1rem;
    line-height: 1rem;
}

.join_code_container {
    display: flex;
}

.qr_code {
    grid-column: 1;
    font-size: 17px;
    justify-self: center;
    color: colors.$black;
}

.join_code {
    font-size: 1.1rem;
    grid-column: 2;
    font-weight: 400;
    justify-self: left;
    padding-right: 20px;
}

.divider {
    grid-column: 1 / span 2;
}

.menu_item {
    display: grid;
    grid-template-columns: 45px auto;
    grid-template-rows: auto;
    column-gap: 5px;
    align-items: center;
    padding: 10px 10px;
}

.exit_icon {
    grid-column: 1;
    color: colors.$black;
    justify-self: center;
    font-size: 17px;
}

.exit_room {
    font-size: 1rem;
    font-weight: 400;
    grid-column: 2;
}

.exit_dialog_content {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    max-width: 500px;
    margin: 0;
}

.exclamation_icon {
    color: colors.$bluePurple;
    font-size: 8rem;
}
