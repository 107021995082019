@use '../../colors';
@use '../../device-mixins';

.dialog :global .MuiDialog-paper {
    position: inherit;
    max-width: none;
    background-color: transparent;
}

.hide_overflow :global .MuiDialog-paper {
    overflow: hidden;
}

.backdrop {
    background-color: rgba(0, 0, 0, 0.8);
}

.img {
    max-height: 80vh;
}

.button {
    position: fixed;
    top: 50%;
    padding: 1rem;
    
    @include device-mixins.md {
        background-color: colors.$black;
        &:hover {
            background-color: #454545;
        }
        
        &:focus {
            outline: 5px solid -webkit-focus-ring-color;
            outline: 1px solid Highlight;
        }
    }
}

.external_link {
    color: colors.$white;
}

.external_link_button {
    @extend .button;

    top: 5%;
    right: 1%;

    @include device-mixins.md {
        right: 5%;
    }
}