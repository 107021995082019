@use '../_colors.scss';
@use '../_device-mixins.scss';

.card {
    display: flex;
    flex-wrap: wrap;
    max-height: 300px;
    overflow: auto;
    border-top: 2px solid colors.$darkGreen;
    border-left: 1px solid colors.$lightGray;
    border-right: 1px solid colors.$lightGray;
    border-bottom: 1px solid colors.$lightGray;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.math_key {
    padding: 10px;
    width: 40px;
    height: 40px;
    margin: 5px;
    font-size: 1.3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$white;
    border: 2px solid colors.$lightGray;
    border-radius: 5px;
    color: colors.$black;
    font-family: 'DejaVu Sans', sans-serif;

    &:hover {
        background-color: colors.$lightGray;
    }
}