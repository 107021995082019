@use '../_colors.scss';
@use '../_device-mixins.scss';

.input {
    background-color: colors.$white;
    width: 100%;
}

.input :global .MuiOutlinedInput-notchedOutline {
    border-color: colors.$lightGray;
}

.input :global .MuiInputBase-input {
    padding: 8px;
}

.close_button {
    padding: 5px;
}

.close_icon {
    font-size: 1.3rem;
}