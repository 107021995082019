@use '../_colors.scss';
@use '../_device-mixins.scss';

.bg_dark_green {
    background-color: colors.$darkGreen;
    color: colors.$white;
}

.hover_bg_dark_green {
    &:hover {
        @extend .bg_dark_green;
    }
}

.chip_base {
    position: 'relative';
    height: fit-content;
    text-transform: none;
    font-size: 1.1rem;
    border-radius: 25px;
    padding: 8px;
    margin-top: 1%;

    @extend .bg_dark_green;
}

.chip_base :global .MuiChip-label {
    white-space: pre-wrap;
}

.bg_light_gray {
    background-color: colors.$lightGray;
    color: colors.$black;
}

.high_z_index {
    z-index: 9;
}