@use '../colors.scss';
@use '../device-mixins';
@use '../pages/dashboard/account/AccountSettings.scss';

.card {
    display: flex;
    flex-direction: column;
}

.account_default_container {
    align-self: center;
    margin: 1%;
    position: relative;
}

.profile_image {
    object-fit: cover;
    border-radius: 100%;
    top: 0;
    bottom: 0;
    height: 6.5rem;
    width: 6.5rem;
}

.state_grade_dropdown {
    & > label {
        font-size: 1rem;
        color: colors.$black;
        font-weight: 400;
    }
}

.loader_container {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(colors.$black, 50%);
}

.account_default {
    font-size: 6.5rem;
}

.pencil_container {
    position: absolute;
    background-color: colors.$black;
    bottom: 5px;
    right: 0;

    &:hover, &:focus {
        background-color: colors.$black;
        box-shadow: 0px 0px 0px 3px rgba(colors.$darkGray, 40%);
    }
}

.pencil {
    font-size: 1rem;
    color: colors.$white;
}

.input_container {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}

.input {
    flex: 0.49;
    margin: 0;
}

.subject_form_control {
    width: 100%;
    margin: 0 0 10px 0;
    @include device-mixins.md {
        width: 49%;
    }
}

.error {
    margin: 10px 0;
    font-size: 16px;
}