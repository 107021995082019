@use 'styles/_colors.scss';

.step_wrapper {
    margin: 50px 50px 0 20px;

    .container_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .left_container {
        width: 100%;
        max-width: 1000px;
        margin-right: 20px;
    }

    .right_container {
        width: fit-content;
        margin-left: 20px;
    }

    h1 {
        color: colors.$gray;
        margin: 0 0 20px 0;
        max-width: 750px;
        line-height: 120%;

        strong {
            color: colors.$black;
            border-bottom: 4px solid colors.$seafoam;
        }
    }

    h3 {
        color: colors.$gray;
        margin: 0;
        padding: 0;
        line-height: 1em;
    }

    .inner_container {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .next_button {
        margin-bottom: 30px;
        .right_chevron {
            transform: rotate(-90deg);
            font-size: 14px;
            margin-left: 15px;
        }
    }

    .back_button {
        margin-right: 20px;
        margin-bottom: 30px;
        .left_chevron {
            transform: rotate(90deg);
            font-size: 14px;
            margin-right: 15px;
        }
    }
}
