@use '../_colors.scss';
@use '../_device-mixins.scss';

.breadcrumb_container {
    text-align: center;
    margin: 3%;

    @include device-mixins.sm {
        display: none;
    }
}

.a {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    border: none;
    margin: auto 5px 10px;
    background-color: #E7EAF5;
    text-decoration: none;
    display: inline-block;
}

.active {
    --var-color: #{colors.$black};

    opacity: 1;
    background-color: var(--var-color);
}

.step_container {
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    transition: opacity 0.1s ease-in;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
        display: none;
    }
}

.step {
    width: 100%;
    height: 100%;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.9);
    font-size: 1.8rem;

    @include device-mixins.sm {
        display: none;
    }
}