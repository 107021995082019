@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
}

.icon {
    font-size: 10rem;

    @include device-mixins.lg {
        font-size: 15rem;
    }
}

.h1 {
    font-size: 3.5rem;
    color: colors.$black;
    font-weight: 900;
}

.p {
    font-size: 1.2rem;
    color: colors.$black;
    font-weight: 700;
    text-align: center;
    max-width: 300px;

    @include device-mixins.sm {
        font-size: 1.5rem;
    }

    @include device-mixins.lg {
        max-width: 100%;
    }
}
.button_container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;

    .button {
        margin: 3%;
        flex-shrink: 0;
    
        @include device-mixins.lg {
            margin: 1% 0;
        }
    }
}


