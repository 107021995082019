@use '../../../../colors';
@use '../../../../device-mixins';

.container {
    background-color: colors.$lightBlueGray;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(10, 1fr);
    align-items: center;
    text-align: center;
    height: calc(100% - 60px);
    overflow-y: auto;
}

.star_person_container {
    grid-row: 2;

    @include device-mixins.md {
        margin-top: 50px;
    }
}

.star_person {
    font-size: 12rem;
}

.h2 {
    text-transform: uppercase;
    margin: 2rem 0 0;
    color: colors.$gray;
    font-size: 0.9rem;
}

.h1 {
    font-size: 1.3rem;
    margin: 5px;
    color: colors.$black;
    grid-row: 3;
    
    @include device-mixins.md {
        margin: 0 10px;
        font-size: 2rem;
    }
}

.button_container {
    margin-top: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    grid-row-start: 7;

    @include device-mixins.md {
        margin: auto;
    }
}

.yes_no_button_container {
    @extend .button_container;

    grid-row-start: 5;
}

.text_button {
    color: colors.$darkBluePurple;
    margin-right: 50px;
}

.three_dot {
    display: block;
    text-align: right;
    background-color: colors.$lightBlueGray;
    padding: 20px 20px 0;

    @include device-mixins.md {
        padding: 20px 40px 0;
    }
}

.no_button {
    background-color: colors.$white;
    color: colors.$darkBluePurple;
    border: 1px solid colors.$darkBluePurple;
    margin-right: 50px;
    width: 100px;

    &:hover {
        background-color: colors.$lightGray;
        color: colors.$darkBluePurple;
    }
}

.yes_button {
    width: 100px;
}

.number_slider_container {
    grid-row-start: 5;
    grid-row-end: 6;
    
    @include device-mixins.md {
        margin: auto;
    }   
}

.error {
    color: colors.$red;
    font-size: 1rem;
    margin-top: 1rem;
    grid-row: 7;
}