@use '../colors';
@use '../device-mixins';

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list_item {
    background-color: rgba(0, 0, 0, 0.10);
    padding: 0 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}

.button {
    font-size: 1rem;
    margin: 10px;
}

.primary_button {
    @extend .button;
    background-color: colors.$seafoam;

    &:hover {
        background-color: colors.$darkGreen;
    }
}

.warning_icon {
    color: colors.$gold;
    font-size: 2rem;
    margin-right: -10px;
    margin-left: 10px;
}

.modal_content {
    max-width: 450px;
    font-size: 1.1rem;

    & > a {
        color: colors.$ruby;

        &:hover {
            color: colors.$darkRuby;
        }
    }
}

.success {
    background-color: colors.$seafoam;
}

.error {
    background-color: colors.$red;
}