@use 'styles/_colors.scss';

.columns {
    display: flex;
    overflow: auto;
    height: 100%;
    background-color: colors.$ghostWhite;

    .right_column {
        flex-grow: 2;
    }
}


.exit_icon_button {
    margin: 25px;
    color: colors.$darkBluePurple;

    &:hover {
        background-color: colors.$lightBluePurple;
    }
}

.exit_icon {
    font-size: 30px;
}
