@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    padding: 2%;
    width: 100%;
}

.options_container {
    max-width: 600px;
    height: 290px;
    overflow: hidden;
    
    margin: auto;
    box-shadow: none;
    position: relative;

    transform: scale(0.9);
    transform-origin: top;

    @include device-mixins.md {
        transform: unset;
        transform-origin: unset;
        max-height: 310px;
    }
}

.avatar_options {
    position: relative;
    height: 100%;
    overflow-y: auto;
}

.scroll_shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.9) 100%
    );
}

.avatar_container {
    margin: 5% 0;

    @include device-mixins.md {
        margin: 3% 0;
    }
}

.avatar {
    margin: auto;
}

.skeleton {
    display: inline-block;
}

.code_name {
    text-align: center;
    color: colors.$black;
    font-style: italic;
    margin: 2% auto;
    font-size: 1.1rem;
    font-weight: 400;
}

.tab_container {
    position: sticky;
    z-index: 2;
    background-color: colors.$white;
    margin-bottom: 20px;

    transform: scale(0.9);
    top: -3px;

    @include device-mixins.md {
        transform: unset;
        top: 0;
    }
}

.tab_container :global .MuiTabs-indicator {
    background-color: colors.$bluePurple;

    display: none;

    @include device-mixins.md {
        display: block;
    }
}

.tab {
    font-size: 1.2rem;
    background-color: colors.$white;
}

.tab_container :global .MuiTab-root.Mui-selected {
    color: colors.$bluePurple;
}

.tab_container :global .MuiTabs-flexContainer {
    @include device-mixins.lg {
        justify-content: center;
    }
}

.no_padding {
    padding: 0;
}

.option {
    margin: 0 10px;
}

.gesture {
    transform: translate(-125px, -106px);
}

.neither {
    font-size: 40px;
    color: colors.$gray;
}

.bg_color {
    height: 65px;
    width: 65px;
    border-radius: 100%;
}

.done_button {
    margin: 20px;
    padding: 5px 20px 5px 20px;
}