@use '../_colors.scss';
@use '../global.scss';
@use '../_device-mixins.scss';

.container {
    overflow: auto;
    height: 100%;
    background-color: colors.$bluePurple;
}

.logo_text_link_link {
    margin: 3% 0;

    @include device-mixins.lg {
        justify-content: flex-start;
        margin: 1% 0 0 1%;
    }
}

.card {
    background-color: colors.$white;
    width: 90%;
    border-radius: 10px;
    padding: 3%;
    display: flex;
    flex-direction: column;
    margin: auto;

    @include device-mixins.lg {
        width: 40%;
        margin: 3% 3% 0 auto;
    }
}

.card_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button {
    margin: 10px 0;
}

.divider {
    margin: 3% 0;
}

.signup_link {
    align-self: center;
    margin-top: 3%;
}
