@use '../../colors';
@use '../../device-mixins';

.dialog_close_button {
    color: colors.$white;
}

.dialog_content {
    display: flex;
    flex-direction: column;
    background-color: colors.$bluePurple;
    align-items: center;
    max-width: 669px;
}

.logo {
    width: 265px;
    height: auto;
    color: colors.$white;
}

.p {
    color: colors.$white;
    margin: 5%;
    font-size: 1.5rem;
}

.a {
    color: colors.$mustard;
    font-weight: 500;
}

.iframe_container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
}

.iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}
