@use '../../colors.scss';
@use '../../device-mixins';
@use '../../global_vars';


.nav_link {
    text-decoration: none;
}

.nav_link_active {
    border-left: 5px solid colors.$mustard !important;
    background-color: rgba(255, 255, 255, 0.20) !important;
}

.link_content_formatting {
    display: flex;
    color: colors.$white;
    align-items: center;
    height: 56px;
    border-left: 5px solid colors.$bluePurple;
    padding: 0 1rem;
    white-space: nowrap;
    margin-bottom: 10px;


    @include device-mixins.sm {
        padding: 0 1.3rem;
    }
}

.nav_link_content_container {
    @extend .link_content_formatting;

    &:hover {
        @extend .nav_link_active;
    }
}

.notification_badge :global .MuiBadge-badge {
    background-color: colors.$ruby;

    @include device-mixins.md {
        left: 5px;
    }
}

.nav_link_icon_container {
    display: flex;
    align-items: center;
    position: relative;
}

.nav_link_icon {
    font-size: 1.6rem;

    @include device-mixins.md {
        margin-left: 6px;
    }
}

.nav_link_text {
    margin-left: 15%;
    font-size: 1.2rem;
    font-weight: 500;

    @include device-mixins.md {
        font-size: 1.7rem;
    }
}

.hidden {
    visibility: hidden;
}

.tooltip {
    height: 40px;
    background-color: colors.$bluePurple;
}

.tooltip_arrow{
    color: colors.$bluePurple;
}