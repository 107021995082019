@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.text_field {
    width: 100%;
    height: 100%;
}

.text_field :global .MuiInputBase-input {
    overflow-y: auto !important;
    height: 100% !important;
}

.text_field :global .MuiInputBase-root {
    height: 100%;
}

.toolbar {
    width: 100%;
    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.green {
    color: colors.$darkGreen !important;
}

.math_keyboard_button {
    color: colors.$darkGray;
    padding: 5px;
    margin: 10px;
}

.math_keyboard {
    position: absolute;
    left: 60px;
    right: 0;
    z-index: 9;
    bottom: 0;

    @include device-mixins.md {
        top: 99%;
        left: 0;
        bottom: 0;
    }

}

.read_aloud_button {
    color: colors.$darkGray;
    margin: 5px;
    padding: 5px;
}
    
.mic_button {
    color: colors.$darkGray;
    margin: 5px;
}

.mic_icon {
    font-size: 1.3rem;
}

.mic_active {
    color: colors.$magenta;
    background-color: rgba(205, 70, 208, 0.1);

    &:hover {
        background-color: rgba(205, 70, 208, 0.1);
    }
}

.toolbar_icons {
    align-content: flex-end;
    padding: 5px;
}

.next_btn_focus_xs {
    @include device-mixins.xs {
        display: none;
    }
}

.next_btn {
    height: 40px;
    align-self: center;
    margin-right: 15px;

    @include device-mixins.sm {
        display: flex;
        width: 148px;
        border-radius: 4px;
        font-size: 1rem;
    }
}

.next_btn:disabled {
    color: colors.$white;
}

.right_arrow_icon {
    font-size: .85rem !important;
}

.file_attachment {
    margin-bottom: 10px;
    
    @include device-mixins.sm {
        margin-right: 20px;
    }

    & div {
        width: 100%;
    }

    & p {
        width: 50%;
        overflow: hidden;
    }
}

.file_attachment_container {
    position: absolute;
    bottom: 55px;
    padding-left: 15px;

    display: flex;
    flex-wrap: wrap;
    width: fit-content;

    & > span {
        margin-right: 1%;
    }
    
    @include device-mixins.sm {
        flex-wrap: nowrap;
    }
}

.dropzone {
    height: 100%;
}
