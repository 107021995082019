@use 'colors';
@use 'device-mixins';
@use 'global_vars';

.container {
    overflow: auto;
    height: 100%;
    background-color: colors.$lightBlueGray;
}

.logo_text_link_link {
    margin: 3%;

    @include device-mixins.lg {
        justify-content: flex-start;
        margin: 0 0 0 1%;
    }
}

.content_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    height: 80%;
    flex-direction: column-reverse;

    @include device-mixins.md {
        flex-direction: row;
    }

    @include device-mixins.lg {
        margin-top: 5%;
    }
}

.text_button_container {
    margin-right: 2%;
    text-align: center;

    @include device-mixins.sm {
        text-align: left;
    }
}

.h1 {
    color: colors.$bluePurple;
    font-size: 4rem;
    margin: 0;

    @include device-mixins.lg {
        font-size: 6rem;
    }
}

.h2 {
    color: colors.$bluePurple;
    margin: 0;

    @include device-mixins.lg {
        font-size: 1.5rem;
    }
}

.button {
    margin: 3% 0;
}

.icon {
    font-size: 200px;
    margin: 5% 0;

    @include device-mixins.lg {
        font-size: 400px;
    }
}
