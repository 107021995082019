@use '../../../colors';
@use '../../../device-mixins';

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1% 2% 6%;
    font-size: 1.2rem;
}

.icon_loader {
    height: 500px;
}

.h1 {
    color: colors.$darkBlue;
    font-size: 1.5rem;
    margin: 1% 0;

    @include device-mixins.sm {
        font-size: 2.5rem;
    }
}

.h2 {
    color: colors.$darkBlue;
    font-size: 1rem;
    font-weight: 500;
    margin: 3% 0 10px;

    @include device-mixins.sm {
        font-size: 1.3rem;
    }
}

.a {
    margin-top: 10px;
    display: block;
    width: fit-content;
    font-size: 1rem;
}

.cards_container {
    display: flex;
    max-height: 350px;
    flex-wrap: wrap;
}

.card {
    padding: 5%;
    flex-basis: 100%;

    @include device-mixins.sm {
        padding: 1%;
    }

    @include device-mixins.md {
        flex-basis: 40%;
    }

    @include device-mixins.xl {
        flex-basis: 25%;
    }
}

.card:only-child {
    flex-basis: 100%;
    margin-right: 0;

    @include device-mixins.md {
        flex-basis: 45%;
    }

    @include device-mixins.xl {
        flex-basis: 25%;
    }
}

.premium_card {
    @extend .card;

    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include device-mixins.sm {
        width: fit-content;
        margin-right: 20px;
    }
}

.premium_card:only-child {
    margin-right: 0;
}

.h3 {
    color: colors.$black;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
}

.caption {
    font-size: 1rem;
}

.pricing_container {
    display: flex;
    flex-direction: column;
}

.toggle_group {
    margin: 1rem 0;
    align-self: center;
}

.toggle_button {
    padding: 5px 2rem;
    text-transform: unset;
    height: fit-content;
}

.price {
    font-size: 2rem;
    font-weight: 600;
}

.discount {
    font-size: 1rem;
}

.features_list_container {
    margin: 1rem 0;
    padding: 0;
}

.features_list {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
}

.features_list_item {
    font-size: 1rem;
}

.h4 {
    margin: 0;
    font-size: 1rem;
}

.button {
    margin: auto auto 10px;
    width: fit-content;
}

.school_plan_card {
    @extend .card;

    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include device-mixins.sm {
        width: fit-content;
        margin-right: 20px;
    }
}

.school_plan_header_container {
    margin-bottom: 2rem; 
}

.school_plan_content_container {
    margin-bottom: 10px;
}

.school_plan_button {
    margin: auto auto 10px;
    width: fit-content;
    white-space: nowrap;
}

.button_link {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: colors.$ruby; 
    text-decoration: underline;
    cursor: pointer;
    font-family: inherit;
    line-height: inherit;
    font-size: 1rem;

    &:hover, 
    &:focus {
        color: colors.$darkRuby; 
        text-decoration: underline;
        outline: none; // Remove the default focus outline, use with caution and consider accessibility
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        font-weight: 500;
    }

    // If you want to hide the button appearance on iOS devices
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.link {
    color: colors.$bluePurple;
    margin-left: 1rem;
    font-size: 1rem;

    &:hover, 
    &:focus {
        color: colors.$darkBlue;
        text-decoration: underline;
        outline: none; // Remove the default focus outline, use with caution and consider accessibility
    }
}