@use '../../colors';
@use '../../device-mixins';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 0 10px 0;
}

.tooltip {
    font-size: 0.9rem;
    max-width: 200px;
    background-color: colors.$darkGray;
}

.tooltip_arrow {
    color: colors.$darkGray;
}

.scaffold_option {
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 10px;
    margin: 0 10px 5px 0;
}

.header_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 280px;
    font-size: 1rem;
    margin: 0;
    font-size: 14px;
}

.disabled {
    opacity: 0.5;
}

.name_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
}

.h4 {
    margin: 0;
}

.description {
    font-size: 11px;
    flex: 0.8;
}

.blue_purple_color {
    color: colors.$bluePurple;
}

.burnt_orange_color {
    color: colors.$burntOrange;
}

.magenta_color {
    color: colors.$magenta;
}

.icon {
    height: 14px;
    width: 14px;
    margin: 2px 5px 5px 0px;
}

.format_radio_group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:not(.scaffold_disabled) {
        cursor: pointer;
    }

    :global .Mui-disabled {
        opacity: 0.5;
    }
}
