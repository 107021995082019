@use 'styles/_colors.scss';
@use 'styles/_device-mixins.scss';

.infobar {
    width: min-content;
    border: none;
    box-shadow: none;
    margin-bottom: 50px;
    padding: 0;
    width: 150px;
    transition: width 350ms ease-in-out;

    @include device-mixins.lg {
        width: 260px;
    }

    &:global(.expanded) {
        width: 150px;

        .infobar_summary {
            display: none;
        }

        @include device-mixins.sm {
            width: 200px;
        }

        @include device-mixins.md {
            width: 300px;
        }

        @include device-mixins.lg {
            width: 400px;
        }

        @include device-mixins.xl {
            width: 560px;
        }
    }

    h2 {
        color: colors.$black;
        margin: 0;
        padding: 10px 0;
    }

    ul {
        margin: 0;
        padding: 10px 20px;
        font-size: 24px;
    }

    li {
        padding-bottom: 20px;
        font-size: 20px;
        font-weight: 300;
        line-height: 120%;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.infobar_summary {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    white-space: normal;
    text-align: left;

    .chevron_icon {
        font-size: 0.8rem;
        margin-left: 10px;
        cursor: pointer;
    }

    &:hover {
        background-color: colors.$lightBluePurple;
    }
}

.infobar_details {
    position: relative;
    margin: 0;
    padding: 28px 20px;
    border-radius: 5px;
    background-color: colors.$lightBluePurple;

    .close_button {
        position: absolute;
        top: 20px;
        right: 15px;
    }

    .close_icon {
        font-size: 1.2rem;
        stroke: colors.$black;
        stroke-width: 1.5px;
    }
}
