@use '../colors';
@use '../device-mixins';

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(35, 38, 201, 0.95);
}

.close_button {
    top: 32px;
    right: 32px;
    position: absolute;
    color: colors.$white;
}

.close_icon {
    font-size: 2rem;
    stroke-width: 2.5px;
    stroke: colors.$white;
}

.content_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;

    h1 {
        text-align: center;
        margin: 0 0 30px;
        font-weight: 700;
        font-size: 25px;
        color: colors.$white;
        font-style: normal;
        line-height: 120%;

        @include device-mixins.lg {
            font-size: 30px;
        }

        @include device-mixins.xl {
            font-size: 35px;
        }

        div {
            display: inline-block;
        }
    }

    .content {
        margin: 0 40px;

        @include device-mixins.lg {
            margin: 0 150px;
        }

        @include device-mixins.xl {
            margin: 0 200px;
        }

        .content_element {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;

            .dot {
                height: 15px;
                width: 15px;
                background-color: #bbb;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
                flex-shrink: 0;

                @include device-mixins.xl {
                    height: 25px;
                    width: 25px;
                    margin-right: 50px;
                }
            }

            h2 {
                color: colors.$white;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                margin: 15px 0;

                @include device-mixins.lg {
                    font-size: 30px;
                    margin: 15px 0;
                }

                @include device-mixins.xl {
                    font-size: 42px;
                    margin: 15px 0;
                }
            }

            &:nth-child(1n) {
                .dot {
                    background-color: colors.$lightYellow;
                }
            }

            &:nth-child(2n) {
                .dot {
                    background-color: colors.$magenta;
                }
            }

            &:nth-child(3n) {
                .dot {
                    background-color: colors.$green;
                }
            }
        }
    }
}

.button_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;

    @include device-mixins.lg {
        margin-top: 40px;
    }

    @include device-mixins.xl {
        margin-top: 50px;
    }
}

.secondary_button {
    margin: 5px;
    padding: 4px 6px;
    color: colors.$bluePurple;
    background-color: colors.$white;
    border: none;

    @include device-mixins.lg {
        margin: 10px;
        padding: 8px 12px;
    }

    &:hover {
        color: colors.$bluePurple;
        background-color: colors.$white;
        border: none;
    }
}
