@use 'styles/colors';
@use 'styles/device-mixins';

.badge {
    margin-left: 15px;
}

.badge_label {
    font-weight: 500;
}

.premium_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
    border: 3px solid colors.$premiumBlue;
    border-radius: 5px;
    display: none;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    &::before {
        content: '';
        border-top: 75px solid colors.$premiumBlue;
        border-left: 75px solid transparent;
        position: absolute;
        right: 0;
        top: 0;
    }

    &::after {
        content: '';
        position: absolute;
        top: 7px; /* Adjust as necessary to move the icon above the border */
        right: 7px; /* Adjust as necessary to align the icon to the right */
        width: 30px; /* Set the width of the icon */
        height: 30px; /* Set the height of the icon */
        background-image: url('../../../../components/icons/svgs/Stars.svg');
        background-size: contain; /* Ensure the icon fits within the dimensions */
        background-repeat: no-repeat;
        z-index: 10; /* Make sure it's above other elements */
    }
}

.show_overlay {
    display: block;
}

.questionmark_icon {
    font-size: 12px;
    margin-left: 5px;
}

.scaffold_container {
    border: 1px solid colors.$lightGray;
    border-radius: 4px;
    padding: 10px 20px 10px;
    width: 100%;
    background-color: colors.$white;
    text-align: left;

    &:first-child {
        margin-bottom: 10px;
    }
}

.show_pointer {
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, .05);
    }
}

.scaffold_header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scaffold_header {
    margin: 2px 0 5px 0;
    color: colors.$black;
}

.scaffold_description {
    font-size: 0.9rem;
    color: colors.$darkGray;
    font-weight: 400;
    margin: 0;
}

.scaffold_input {
    width: 100%;
    margin-top: 1rem;

    :global(.MuiFilledInput-root){
        border-radius: 4px;
    }

    :global(.MuiFilledInput-root::before){
        border: none;
        border-radius: 4px;

        &:hover{
            border: none;
        }
    }

    :global(.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before){
        border: none;
    }
}

.close_icon { 
    font-size: 1rem;
}

.plus_icon {
    color: colors.$bluePurple;
}

.magic_wand_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.error_message {
    color: colors.$darkRed;
    font-size: 1rem;
    padding-top: 10px;
}