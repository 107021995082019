@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
    background-color: colors.$bluePurple;
    height: 100%;

    @include device-mixins.md {
        padding-top: 5%;
    }
}

.logo_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: colors.$white;
}

.logo_icon {
    font-size: 25vw;
    color: inherit;

    @include device-mixins.md {
        font-size: 15vw;
    }
}

.logo_text {
    font-weight: 500;
    text-align: center;
    padding: 3% 10%;
}

.three_dot {
    text-align: right;
    padding: 20px 40px;
    background-color: colors.$bluePurple;

    & > button {
        color: colors.$lightGray;
    }
}
