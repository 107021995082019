@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    z-index: 2;
    align-items: center;
    justify-content: center;
    width: 300px;

    @include device-mixins.lg {
        width: 500px;
    }
}

.yellow {
    color: colors.$mustard;
}

.white {
    color: colors.$white;
}

.text {
    position: absolute;
    font-weight: 500;
    padding: 0 10%;
    text-align: center;
}
