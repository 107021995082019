@use '../../_colors.scss';
@use '../../_device-mixins.scss';

.dialog_content {
    display: flex;
    max-width: 500px;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
}

.content_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.warning_container {
    background-color: #FFF3E5;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include device-mixins.md {
        height: 80px;
        width: 80px;
    }
}

.warning {
    color: #EF7B29;
    font-size: 35px;
    margin-top: -6px;

    @include device-mixins.md {
        font-size: 50px;
    }
}

.title {
    font-size: 1.3rem;
    margin: 0 0 15px 0;

    @include device-mixins.md {
        font-size: 1.8rem;
    }
}

.subtitle {
    color: colors.$black;
    margin-top: 0;
    font-weight: 500;
    font-size: 1rem;

    @include device-mixins.md {
        font-size: 1.2rem;
    }
}

.button_container {
    display: flex;
    width: 75%;
    justify-content: space-evenly;
    margin-top: 20px;
}

.button {
    min-width: 100px;
}
.anchor_link {
    color: colors.$black;
}
