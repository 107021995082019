@use '../../colors.scss';
@use '../../device-mixins';
@use '../../global_vars';

.container {
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    background-color: colors.$bluePurple;
    transition: width .2s ease-out;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 4;
}

.expanded {
    width: 200px;

    @include device-mixins.md {
        width: 300px;
    }
}

.collapsed {
    width: 0;

    @include device-mixins.md {
        width: 6rem;
    }
}

.hidden {
    visibility: hidden;
}

.sidebar_header {
    height: global_vars.$footer_height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 1rem;
    margin-bottom: 40px;

    @include device-mixins.sm {
        padding: 0 1.3rem;
    }
}

.hamburger_button {
    color: colors.$white;
}

.hamburger_icon {
    font-size: 1.6rem;
}

.logo_badge_container {
    position: relative;
}

.logo_text_link_link {
    margin: auto;
    height: 1.6rem;
}

.logo_text_link_icon {
    width: 80%;

    @include device-mixins.lg {
        width: 90%;
    }
}

.links_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include device-mixins.sm {
        height: 80%;
    }
}

.nav_link_active {
    border-left: 5px solid colors.$mustard !important;
    background-color: rgba(255, 255, 255, 0.20) !important;
}

.link_content_formatting {
    display: flex;
    color: colors.$white;
    align-items: center;
    height: 56px;
    border-left: 5px solid colors.$bluePurple;
    padding: 0 1rem;
    white-space: nowrap;


    @include device-mixins.sm {
        padding: 0 1.3rem;
    }
}

.nav_link_content_container {
    @extend .link_content_formatting;

    &:hover {
        @extend .nav_link_active;
    }
}

.nav_link_text {
    margin-left: 15%;
    font-size: 1.2rem;
    font-weight: 500;

    @include device-mixins.md {
        font-size: 1.7rem;
    }
}

.nav_sub_link_text {
    margin-left: 16%;
    font-size: .9rem;

    @include device-mixins.md {
        font-size: 1.2rem;
    }
}

.nav_sub_link_icon {
    font-size: 1.2rem;

    @include device-mixins.md {
        margin-left: 6px;
    }
}

.bottom_nav_items_container {
    width: 100%;
    bottom: 0;

    @include device-mixins.md {
        bottom: 11%;
    }
}

.my_account_button {
    background-color: colors.$bluePurple;
    color: colors.$white;
    font-size: 1.2rem;
    width: 100%;
    border: none;
    cursor: pointer;
    border-left: 5px solid colors.$bluePurple;

    @include device-mixins.md {
        font-size: 1.7rem;
    }
}

.my_account_content_container {
    display: flex;
    align-items: center;
    height: 56px;
    justify-content: space-between;
}

.my_account_icon_container {
    width: 1.6rem;
    position: relative;
}

.profile_image {
    object-fit: cover;
    border-radius: 100%;
    top: 0;
    bottom: 0;
    height: 1.8rem;
    width: 1.8rem;
    margin-top: 7px;

    @include device-mixins.md {
        height: 2.5rem;
        width: 2.5rem;
    }
}

.my_account_icon {
    font-size: 1.8rem;
    margin-top: 7px;

    @include device-mixins.md {
        font-size: 2.5rem;
    }
}

.my_account_icon_premium {
    border: 2px solid colors.$gold;
    border-radius: 100%;
    padding: 2px;
    margin-left: -2px;
}

.my_account_icon_stars {
    font-size: 1rem;
    position: absolute;
    bottom: 14px;
    left: 22px;
    display: none;

    @include device-mixins.md {
        display: block;   
    }
}

.my_account_text_container {
    margin-left: 15%;

    @include device-mixins.md {
        margin-left: calc(15% + 5px);
    }
}

.my_account_text {
    font-size: 1.2rem;
    font-weight: 500;

    @include device-mixins.md {
        font-size: 1.7rem;
    }
}

.premium_text {
    color: colors.$gold;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    display: none;

    @include device-mixins.md {
        display: block;
    }
}

.resources_container {
    margin-top: 20px;
}

.resources_link {
    @extend .link_content_formatting;

    height: 45px;
    padding: 0 1.6rem;

    &:hover {
        background-color: rgba(255, 255, 255, 0.20);
        border-left: 5px solid colors.$mustard;
    }
}

.upgrade_button_container {
    position: relative;
}

.upgrade_button_icon_container {
    width: 100%;
    display: flex;
    align-items: center;
}

.upgrade_button_icon {
    margin: 10px auto;
}

.upgrade_button {
    bottom: 0;
    margin: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    
    text-transform: capitalize;
    background-color: colors.$white;
    width: 80%;
    color: colors.$bluePurple;

    &:hover {
        background-color: rgba(255, 255, 255, 0.90);
    }

    @include device-mixins.sm {
        margin-bottom: -20px;
    }
}

.try_premium_button {
    @extend .upgrade_button;

    margin-top: 40px;
}

.free_trial_text {
    color: colors.$white;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 10%;
    font-size: .9rem;
}

.free_trial_remaining_days {
    color: colors.$mustard;
    font-weight: bold;
}

.results_tab {
    & > div > p {
        margin-top: 3px;
    }
}

.tooltip {
    height: 40px;
    background-color: colors.$bluePurple;
}

.tooltip_arrow{
    color: colors.$bluePurple;
}