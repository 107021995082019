@use '../_colors.scss';
@use '../device-mixins.scss';

.container {
    color: colors.$white;
    background-color: colors.$bluePurple; 
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    width: 100%;
}

.title {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    margin: 40px auto;
}

.onboard_title {
    margin: auto;
    max-width: 450px;

    svg {
        font-size: 36px;
        margin-right: 5px;
    }

    @include device-mixins.md {
        max-width: 550px;
    }
}

.logo {
    color: colors.$white;
    display: flex;
    justify-content: center;
}

.progress_bar {
    margin-top: 20px;

    & > span {
        height: 10px;
        border-radius: 5px;
        background-color: colors.$white;

        & > span {
            background-color: colors.$seafoam;
        }
    }
}

.screen_container {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 300px;

    label {
        font-size: 16px;
    }

    @include device-mixins.md {
        max-height: 450px;
    }
}

.footer_styles {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin: 20px 0 100px 0;

    button {
        width: 100%;
    }
}

.footer {
    @extend .footer_styles;
}

.splash_footer{
    @extend .footer_styles;    
}

.back_button {
    background-color: colors.$white;
    color: colors.$bluePurple;
    width: 100%;
    border: none;
    flex-shrink: 0;
}

.next_button {
    background-color: colors.$seafoam;
    color: colors.$white;
    flex-shrink: 0;

    &:disabled {
        background-color: #018b74;
        color: colors.$lightGray;
    }

    &:hover {
        background-color: colors.$viridianGreen;
    }
}

