@use '_screen-sizes.scss';

// Extra small devices
@mixin xs {
    @media (min-width: #{screen-sizes.$screen-xs-min}) {
        @content;
    }
}

// Small devices
@mixin sm {
    @media (min-width: #{screen-sizes.$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (min-width: #{screen-sizes.$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (min-width: #{screen-sizes.$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{screen-sizes.$screen-xl-min}) {
        @content;
    }
}
