@use '../_colors.scss';

.speaker_button {
    color: colors.$darkGray;
}

.speaker_button_active {
    color: colors.$magenta;
    background-color: rgba(205, 70, 208, 0.1);

    &:hover {
        background-color: rgba(205, 70, 208, 0.1);
    }
}

.speaker_icon {
    font-size: 1.2rem;
}