@use '../_colors.scss';
@use '../_device-mixins.scss';

.button {
    color: colors.$ruby;

    &:focus-visible, &:hover {
        color: colors.$darkRuby;
    }
}

.icon {
    color: inherit;
}

.input {
    font-size: 1.2rem;
}
