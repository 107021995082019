@use '../../colors';
@use '../../device-mixins';
@use '../../global_vars';

.container {
    width: 100%;
    transition: all .2s ease;
    background-color: colors.$lightBlueGray;
    height: 100%;
    overflow: auto;

    @include device-mixins.md {
        width: calc(100% - 6rem);
        margin-left: 6rem;
    }
}

.sidebar_extended {
    width: 100%;

    @include device-mixins.md {
        width: calc(100% - 300px);
        margin-left: 300px;
    }
}

.message_container {
    display: flex;
    width: 400px;
    margin: 10px;
}

.link {
    margin-top: 10px;
    text-align: center;
    font-weight: 500;
    color: colors.$gold;
}

.image_container {
    margin-right: 15px;
}

.image {
    object-fit: cover;
    object-position: 50% 20%;
    border-radius: 100%;
    top: 0;
    bottom: 0;
    height: 50px;
    width: 50px;
}

.text {
    font-size: 1rem;
    font-weight: 500;
}