@use '../../_colors.scss';
@use '../../_device-mixins.scss';

.content_header {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 20px;
    align-items: center;
}

.content_header_name {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
    display: flex;
    padding-left: 0;
    align-items: flex-end;
}

.icon {
    margin-right: 8px;
}

.display_name {
    margin: 0;
}

.scaffold_body_container {
    height: calc(100% - 60px);
    overflow-y: auto;
    position: relative;
}

.scaffold_text {
    overflow-y: auto;
    height: inherit;
}

.sentence-stems_body {
    @extend .scaffold_body;
    
    &:hover {
        background-color: #FAF0EC;
        border-left: 2px solid colors.$burntOrange;

        & > div {
            background-color: #FAF0EC;
        }
    }
}

.outline_body {
    @extend .scaffold_body;

    &:hover {
        background-color: #FDF8E8;
        border-left: 2px solid colors.$mustard;

        & > div {
            background-color: #FDF8E8;
        }
    }
}

.outline_icon {
    @extend .outline_hover;

    svg {
        font-size: 1.5rem;
        margin-right: 5px;
    }
}

.sentence-stems_icon {
    @extend .sentence-stems_hover;

    svg {
        font-size: 1.8rem;
    }
}

.copy_button {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: sticky;
    bottom: -1px;
    right: 12px;
    padding: 5px 0;
}

.sentence-stems_color {
    color: colors.$burntOrange !important;

    & svg {
        color: colors.$burntOrange !important;
    }
}

.outline_color {
    color: colors.$mustard !important;

    & svg {
        color: colors.$mustard !important;
    }
}

.sentence-stems_hover {
    &:hover {
        @extend .sentence-stems_color;
        background-color: colors.$lightGray;
        font-weight: 500;
        transition: font-weight 0.1s ease;
    }
}

.outline_hover {
    &:hover {
        @extend .outline_color;
        background-color: colors.$lightGray;
        font-weight: 500;
        transition: font-weight 0.1s ease;
    }
}

.sentence-stems_toolbar_icon {
    @extend .sentence-stems_hover;

    font-size: 1.5rem !important;
    margin-right: 5px;
}

.outline_toolbar_icon {
    @extend .outline_hover;
}

.scaffold_body {
    border-radius: 0px 6px 6px 0px;
    padding: 10px 10px 0;
    border-left: 2px solid transparent;

    & > div {
        background-color: colors.$white;
    }
}
