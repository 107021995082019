@use '../_colors.scss';
@use '../_device-mixins.scss';


.h3 {
    margin-top: 0;
    color: colors.$black;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
}

.attachments_container {
    display: flex;
    gap: 1rem;
}
