@use '../colors';
@use '../device-mixins';

.left_side {
    display: unset;
    background-color: colors.$bluePurple;
    color: colors.$white;
    width: 100%;
    align-content: center;
}

.left_side_wrapper {
    padding: 0px 10%;
}

.left_side_header {
    color: colors.$white;
    text-align: left;
}

.question_response {
    border: 1px solid colors.$white;
    border-radius: 4px;
}

.response_text {
    padding: 20px;
    max-height: 400px;
    overflow: auto;
}
