@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    background-color: colors.$bluePurple;

    &:hover {
        background-color: colors.$bluePurple;
    }

    padding: 2px 13px 2px 6px;
    text-transform: none;
    align-self: center;
    margin: 3% 0 0;
}

.tos_text {
    font-size: .85rem;
    color: colors.$darkGray;
    width: 90%;
    margin: 3% 0;

    & > a {
        color: colors.$ruby;
    }

    @include device-mixins.sm {
        width: 75%;
    }
}
