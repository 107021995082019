@use '../_colors.scss';
@use '../_device-mixins.scss';
@use '../molecules/FeedbackChip.scss';

.chip {
    font-size: 1rem;
    margin: 5px;

    &:first-child {
        margin-left: 0;
    }
}

.error {
    color: colors.$red;
}

.text_field :global .MuiInputBase-root {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    margin-top: 30px;
}

.text_field :global .MuiInputBase-input {
    margin-top: 10px;
}
