@use '../../colors';
@use '../../device-mixins';

.settings_card_container {
    padding-top: 10px;
    box-shadow: none;
}

.settings_container {
    border-radius: 4px;
    border: 1px solid colors.$lightGray;
    padding: 20px 0 0;
    margin: auto 20px 20px;
}

.settings_item_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.max_pairings_container {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form_label {
    margin-left: 10px;
    margin-bottom: 25px;
    color: colors.$black !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;

    @include device-mixins.md {
        min-width: 200px;
    }

    p {
        display: flex;
        gap: 10px;

        .tooltip {
            color: colors.$lightGray;
        }
    }
}

.subtext {
    color: colors.$gray;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.max_pairings_input {
    max-width: 70px;
    text-align: center;
    font-size: 1rem;
    padding: 10px;
    margin-bottom: 25px;

    & > p {
        margin: 5px 0 0;
    }

    & > div > div > input {
        background-color: colors.$white;
        text-align: center;
        font-size: 1rem;
        padding: 5px 0 5px;
    }
}

.format_radio_group_container {
    padding: 0 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.format_radio_group {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-grow: 1;
    font-size: 1rem;
    gap: 35px;
    margin-bottom: 25px;
}

.writing_scaffols_section_container {
    padding: 0 15px;
    display: flex;
    align-items: space-between;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex-flow: column wrap;
}

.switch_form_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.premium_container {
    position: relative;
}

.premium_overlay {
    margin: -15px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid colors.$premiumBlue;
    border-radius: 0 0 3px 3px;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;

    &::before {
        content: '';
        width: 50px;
        background-color: colors.$premiumBlue;
        height: 100%;
        top: 0;
        right: 0;
        position: absolute;
        background-image: url('../../../components/icons/svgs/Stars.svg');
        background-size: auto; /* Ensure the icon fits within the dimensions */
        background-repeat: no-repeat;
        background-position: center; /* Center the background image */
    }
}
