@use '../_colors.scss';
@use '../_device-mixins.scss';

.header_font_size {
    font-size: 25px;

    @include device-mixins.sm {
        font-size: 30px;
    }

    @include device-mixins.md {
        font-size: 35px;
    }
}

.header {
    color: colors.$white;
    text-align: center;
    font-weight: 900;

    @extend .header_font_size;
}

.form {
    margin-top: 20px;
    text-align: left;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    overflow: auto;

    label {
        font-size: 20px;
        font-weight: 700;
        white-space: break-spaces;
    }

    @include device-mixins.md {
        width: 600px;
    }
}

.scroll_shadow {
    position: absolute;
    left: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(
        to bottom,
        rgba(0, 244, 247, 0) 0%,
        rgba(0, 0, 0, 0.1) 100%
    );
}

.bottom_shadow {
    @extend .scroll_shadow;
    bottom: 0px;
}

.top_shadow {
    @extend .scroll_shadow;
    top: 0px;
    transform: rotate(180deg);
}

.list_styles {
    color: colors.$white;
    font-size: 26px;
    font-weight: 500 !important;
    list-style-position: outside;
    list-style-type: disc;
    align-self: center;
    padding-left: 40px;
    text-align: left;

    li {
        margin-bottom: 1rem;
        padding-left: 10px;
        display: list-item;
    }
}

.paragraph {
    color: colors.$white;
    font-size: 26px;
    font-weight: 500 !important;
    width: 70%;
}

.slider_header {
    text-align: left;
    font-weight: 900;
    width: 70%;

    @extend .header_font_size;
}

.slider {
    margin-top: 30px;
    align-self: center;
    color: colors.$seafoam;

    :global .MuiSlider-rail {
        background-color: colors.$white;
        opacity: 1;
    }

    @include device-mixins.md {
        width: 532px;
    }
}

.slider_value {
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
}

.paragraph_header {
    font-weight: 900;
    width: 90%;
    text-align: left;

    @extend .header_font_size;
}

.footer {
    margin-top: 60px;
    width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.content_wrapper {
    position: absolute;
    top: 40%;
}

.avatars {
    svg {
        position: absolute;
    }
}

.avatar_100 {
    rotate: -10deg;
    top: -50%;
    left: -5rem;
}

.avatar_200 {
    rotate: -15deg;
    top: -90%;
    left: 15%;
}

.avatar_300 {
    rotate: 15deg;
    top: -45%;
    left: 30%;
}

.avatar_400 {
    rotate: 0deg;
    top: -100%;
    right: 46%;
}

.avatar_500 {
    rotate: 12deg;
    top: -67%;
    right: 25%;
}

.avatar_600 {
    rotate: 10deg;
    top: -78%;
    right: -5rem;
}

.h1 {
    margin: 3rem 0;
    font-weight: 700;
    color: colors.$white;
    font-size: 3.5rem;

    div {
        display: inline-block;
    }
}

.close_button {
    top: 32px;
    right: 32px;
    position: absolute;
    color: colors.$white;
}

.close_icon {
    font-size: 2rem;
    stroke-width: 2.5px;
    stroke: colors.$white;
}
