@use '../_colors.scss';
@use '../_device-mixins.scss';

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    .dot {
        width: 10px;
        height: 10px;
        background-color: colors.$gray;
        border-radius: 50%;
        margin: 0 4px;
        animation: dotAnimation 1.4s infinite ease-in-out both;
    }

    .dot1 {
        animation-delay: -0.32s;
    }

    .dot2 {
        animation-delay: -0.16s;
    }

    .dot3 {
        animation-delay: 0s;
    }
}

@keyframes dotAnimation {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}