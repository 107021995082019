h5 {
    font-size: 16px;
    font-weight: 700;
    width: 160px;
    margin: 0 0 8px;
    padding: 0;
}

h6 {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.section {
    margin-bottom: 24px;

    &.inline {
        display: flex;
    }

    .file_attachment_wrapper {
        display: flex;

        .file_attachment {
            margin: 0 10px 0 0;
            width: 135px;
            height: 65px;
        }
    }

    .criteria_list_container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
        gap: 14px 18px;
        width: 100%;
    }
}
