@use '../../../../colors';
@use '../../../../device-mixins';

.container {
    background-color: transparent;
    height: 100%;
}

.breadcrumbs {
    position: absolute;
    margin: 2rem auto !important;
    width: 100%;
}

.columns_container {
    overflow: auto;
    display: grid;
    grid-template-columns: 100% 100%;
    grid-template-rows: 1fr;

    @include device-mixins.sm {
        grid-template-columns: 50% 50%;
    }

    @include device-mixins.lg {
        grid-template-columns: 40% 60%;
    }
}

.left_side_container {
    align-content: center;  
    background-color: colors.$bluePurple;
    height: 100%;
}

.right_side {
    height: 100%;

    .waiting_text{
        text-align: center;
        margin-bottom: 20px;
    }

    @include device-mixins.md {
        grid-column: unset;
    }
}

.right_side_wrapper {
    padding: 0px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include device-mixins.lg {
        height: 100%;
    }
}

.h1 {
    color: colors.$black;
    margin: 0 0 10px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;

    @include device-mixins.md {
        font-size: 2rem;
    }
}

.h2 {
    color: colors.$black;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.ul {
    padding: 0 15px;
    margin: 0;

    li {
        margin-bottom: 25px;
    }
}

.h3 {
    color: colors.$black;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.a {
    text-decoration: underline;
    text-decoration-color: colors.$black;
}

.avatar_container {
    text-align: center;
    align-content: center;
    justify-content: center;
    height: calc(100% - 56px);
}

.instructions_container {
    font-size: 1rem;
    margin-bottom: 20%;

    @include device-mixins.md {
        font-size: 1.3rem;
    }
}

.threedot {
    display: block;
    text-align: right;
    padding: 20px 20px 0;
    align-self: start;

    @include device-mixins.md {
        padding: 20px 40px 0px 0px;
    }
}
