@use 'styles/_colors.scss';
@use 'styles/_device-mixins.scss';

.tabs {
    width: max-content;
    margin: 10px 20px 10px 60px;
    padding: 0px;

    :global(.MuiTabs-flexContainer) {
        border-left: 2px colors.$lightMetalGray solid;
    }

    :global(.MuiTabs-indicator) {
        left: 0;
        width: 2px;
    }

    @include device-mixins.xl {
        margin: 20px 80px 20px 100px;
    }
}

.tab {
    font-size: 18px;
    color: colors.$black;
    font-weight: 300;

    &:global(.MuiButtonBase-root) {
        align-items: flex-start;
        min-height: auto;
        min-width: auto;
        padding: 0px;
        margin: 12px 15px;
    }

    &:global(.Mui-selected) {
        color: colors.$black;
        font-weight: 600;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
