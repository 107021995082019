@use '../_colors.scss';

.container {
    width: 100%;
    margin-top: 5px;

    & > div {
        background-color: colors.$white;
        border-radius: 5px;
    }
}

.heading {
    margin: 15px 0 8px 0;
}

.form_control {
    width: 100%;
}

.menu { 
    max-height: 50%;
}

.dropdown_label {
    font-weight: bold;
    padding-bottom: 5px;
    color: colors.$white;
    background-color: transparent;
}

.select_styles {
    & > div {
        padding-top: 4px;
    }
}

.placeholder_text {
    color: colors.$gray;
}