@use 'styles/_colors.scss';
@use 'styles/_device-mixins.scss';

.card_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border: 1px solid colors.$lightGray;
    border-radius: 12px;
    padding: 12px;
    background-color: colors.$white;

    .content_container {
        align-self: start;
    }

    h4 {
        margin: 0;
        color: colors.$black;
        font-size: 16px;
        font-weight: 700;
    }

    p {
        margin-top: 10px;
        color: colors.$gray;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2em;
    }

    .button_container {
        display: flex;
        align-items: center;
    }

    button {
        margin: 0 5px;
        color: colors.$bluePurple;
        border: colors.$bluePurple 1px solid;
        border-radius: 4px;
        height: auto;
        text-decoration: none;
        padding: 4px 8px;
        line-height: 1em;
        min-width: initial;

        &:hover {
            text-decoration: none;
            border: colors.$bluePurple 1px solid;
        }

        &.text {
            padding: 0;
            height: 0;
            color: colors.$black;
            border: none;
            text-decoration: underline;
        }

        &.outline_disabled {
            color: colors.$lightGray;
            border: colors.$lightGray 1px solid;
        }

        &:global(.Mui-disabled) {
            background-color: colors.$lightGray;
            color: colors.$black;
            border: none;
        }
    }
}
