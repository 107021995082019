@use '../_colors.scss';
@use '../_device-mixins.scss';

.close_button {
    top: 22px;
    right: 12px;
    position: absolute;
    color: colors.$white;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    text-align: center;
}

.swipe_hand {
    font-size: 151px;
}

.overlay {
    color: colors.$white;
    z-index: 99999;

    @include device-mixins.sm {
        display: none;
    }
}
