@use 'styles/colors';

.modal_contents {
    padding: 40px 80px;

    h2 {
        margin: 0 0 30px;
        color: colors.$black;
        font-size: 32px;
        font-weight: 700;
    }
}

.input {
    display: block;
    margin: 20px 0 0;
    width: 100%;
    min-width: 400px;

    :global(.MuiInputBase-root) {
        width: 100%;
    }
}

.input_description {
    textarea {
        height: 90px !important;
        overflow: auto !important;
    }
}

.char_count {
    text-align: right;
    color: colors.$gray;
    font-size: 1rem;
}

.button_container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    button {
        padding: 5px 25px;
    }
}
