@use '../colors';

.container {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 2%;
    right: 1%;
}

.popper_content {
    background-color: colors.$bluePurple;
    border-radius: 8px;
    border: 1px solid #434D5B;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 0.7);
    padding: 0.75rem;
    color: colors.$white;
    opacity: 1;

    margin-top: 10px;

    display: flex;
    flex-direction: row-reverse;
}

.close_button {
    padding: 0;
    align-self: flex-start;
    color: colors.$white;
}

.close_icon {
    font-size: 1rem;
}
