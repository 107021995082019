@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.icon {
    font-size: 10rem;
    color: colors.$darkBlue;
}

.text {
    font-size: 2.5rem;
    color: colors.$darkBlue;
    margin-top: 1rem;
    font-weight: 500;
}