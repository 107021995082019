@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    font-size: 6rem;
}

.p {
    color: colors.$black;
    margin-top: 1rem;
}