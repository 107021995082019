@use '../_colors.scss';

.container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: colors.$darkGray;
    margin-bottom: 3%;
}

.validation_req {
    display: flex;
    align-items: center;
    justify-content: center;

    & > p {
        margin-left: 10px;
        margin-right: 10px;
        font-weight: inherit;
    }
}

.valid {
    color: colors.$green;
    font-weight: 900;
}
