@use '../colors';
@use '../device-mixins';

.container {
    position: relative;
}

.overlay {
    position: absolute;
    background-color: rgba(0,189,157, 0.2);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}