@use 'styles/_colors.scss';
@use 'styles/_device-mixins.scss';

.input {
    margin-bottom: 20px;

    :global(.MuiOutlinedInput-root),
    textarea {
        min-height: 300px;
    }
}

.error {
    font-size: 1rem;
    text-align: left;
    margin: 0 0 20px 0;
}

.topic_field {
    display: block;
    width: 100%;

    @include device-mixins.lg {
        width: 50%;
    }
}

.topic_label {
    font-size: 2px;
    color: colors.$gray;
    padding-bottom: 10px;
    display: flex;
    gap: 1rem;

    strong {
        font-weight: 700;
        color: colors.$black;
    }
}

.tooltip {
    display: inline;
    vertical-align: middle;
    line-height: 1em;
}

.img {
    cursor: pointer;
}

.close {
    font-size: 14px;
}

.topic_input {
    width: 100%;
}
