@use '../_colors.scss';
@use '../_device-mixins.scss';

.slider {
    margin-top: 40px;
    margin-bottom: 10px;
    color: colors.$bluePurple;
    width: 250px;

    @include device-mixins.md {
        width: 300px;
    }
}

.slider :global .MuiSlider-markLabel {
    top: -40px;
    font-weight: 600;
    color: colors.$black;
    font-size: 1rem;

    @include device-mixins.md {
        font-size: 1.2rem;
    }
}

.slider :global .MuiSlider-mark {
    visibility: hidden;
}

.slider :global .MuiSlider-thumb {
    height: 20px;
    width: 20px;
    background-color: colors.$white;
    border: 2px solid currentColor;

    &:hover {
        box-shadow: 0px 0px 0px 8px rgb(38 41 170 / 16%);
    }

    &.Mui-focusVisible {
        box-shadow: 0px 0px 0px 8px rgb(38 41 170 / 16%);
    }

    &.Mui-active {
        box-shadow: 0px 0px 0px 14px rgb(38 41 170 / 16%);
    }
}
