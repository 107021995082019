@use '../_colors.scss';

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.form_control {
    min-width: 150px;
    text-align: center;
    width: 100%;

    & div {
        font-size: 1rem !important;
    }
}

.label {
    text-align: left;
    color: colors.$darkBlue;
    font-size: .9rem;
    font-weight: 500;
}

.input {
    width: 100%;
    border: none;
    background-color: colors.$white;
}

.form_control :global .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input:focus {
    background-color: unset;
}

.menu { 
    max-height: 50%;
}

