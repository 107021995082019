@use '../_colors.scss';
@use '../_device-mixins.scss';

.container {
    overflow: auto;
    height: 100%;
    background-color: colors.$bluePurple;
}

.logo_text_link_link {
    margin: 3% 0;

    @include device-mixins.lg {
        justify-content: flex-start;
        margin: 1% 0 0 1%;
    }
}

.card {
    background-color: colors.$white;
    width: 90%;
    border-radius: 10px;
    padding: 3%;
    display: flex;
    flex-direction: column;
    margin: auto;

    @include device-mixins.lg {
        width: 40%;
        margin: 3% 3% 0 auto;
    }
}

.h1 {
    font-size: 2rem;
    font-weight: 700;
    color: colors.$darkBlue;
    margin-top: 3%;
    margin-bottom: 0;
    text-align: center;

    @include device-mixins.sm {
        margin-top: 0;
        font-size: 3rem;
    }
}

.caption {
    font-size: 1rem;
    font-weight: 400;
    color: colors.$darkBlue;
    margin: 3% 0;

    @include device-mixins.sm {
        margin: 1% 0;
        font-size: 1.2rem;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.verification_button {
    margin: 10px auto 50px 0;
    font-size: .9rem;
    padding: 5px 10px;
    text-align: left;
}

.error_text {
    font-size: 1rem;
    text-align: center;
}
