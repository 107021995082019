@use '../_colors.scss';
@use '../_device-mixins.scss';

.tools_card {
    display: none;

    @include device-mixins.sm {
        display: block;
        background-color: colors.$lightBlueGray;
        padding: 0 0 0 20px;
        box-shadow: none;
    }
}

.content_card {
    background-color: colors.$white;

    box-sizing: border-box;
    box-shadow: none;

    border-style: solid;
    border-width: 1px;
    border-color: #C7BECD;
    border-radius: 0;


    padding: 0 20px 20px;
    height: 100%;
    position: relative;

    @include device-mixins.sm {
        margin-left: 20px;
        border-radius: 8px;
    }
}

.container {
    overflow: hidden;

    @include device-mixins.xs {
        grid-row: 2;
        grid-column: 1;
    }

    @include device-mixins.sm {
        grid-row: auto;
        grid-column: 3;
    }
}

.h2 {
    margin: 0 0 10px 0;
    color: colors.$black;
    font-size: 1.2rem;
}

.translate_color {
    color: colors.$magenta !important;

    & svg {
        color: colors.$magenta !important;
    }
}

.sentence-stems_color {
    color: colors.$burntOrange !important;

    & svg {
        color: colors.$burntOrange !important;
    }
}

.outline_color {
    color: colors.$mustard !important;

    & svg {
        color: colors.$mustard !important;
    }
}

.sentence-stems_hover {
    &:hover {
        font-weight: 500;
        transition: font-weight 0.1s ease;

        @extend .sentence-stems_color;
        background-color: transparent;

        @include device-mixins.sm {
            background-color: colors.$lightGray;
        }
    }
}

.outline_hover {
    &:hover {
        font-weight: 500;
        transition: font-weight 0.1s ease;

        @extend .outline_color;
        background-color: transparent;

        @include device-mixins.sm {
            background-color: colors.$lightGray;
        }
    }
}

.translate_hover {
    &:hover {

        font-weight: 500;
        transition: font-weight 0.1s ease;

        @extend .translate_color;
        background-color: transparent;

        @include device-mixins.sm {
            background-color: colors.$lightGray;
        }
    }
}

.icon {
    margin-right: 8px;
}

.toolbar_icon {
    margin-right: 8px;
    font-size: 1.3rem !important;
}

.translate_toolbar_icon {
    @extend .translate_hover;
}

.toolbar_button {
    grid-column: 1;
    text-transform: none;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 400;
    color: colors.$black;
    margin-bottom: 8px;

    @include device-mixins.sm {
        width: fit-content;
        display: inline-flex;
        color: colors.$gray;
    }
}

.translate_icon {
    @extend .translate_hover;

    svg {
        font-size: 1.5rem;
        margin-right: 5px;
    }
}

.hide_header_btn {
    @include device-mixins.xs {
        display: none;
    }
}

.show_header_btn {
    @include device-mixins.xs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: colors.$white;
    }
}

.show_header_xs {
    @include device-mixins.sm {
        display: none;
    }
}

.threedot_xs {
    & > svg {
        rotate: 90deg;
    }
}

.done_btn_xs {
    color: colors.$black;

    &:hover {
        background-color: colors.$lightBlueGray;
    }
}

.checkmark_icon {
    font-size: 16px !important;
}
