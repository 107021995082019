@use 'colors';
@use 'device-mixins';

:global(body) {
    font-family: Satoshi, Helvetica, Arial, sans-serif;
    margin: 0 !important;
    padding: 0 !important;
    min-height: 100%;
    white-space: pre-wrap;
    line-height: 1.5;
    letter-spacing: 0.00938em;

    h1, h2, h3 {
        color: colors.$bluePurple;
    }

    p {
        margin: 0;
    }
}

:global(html) {
    overflow: hidden;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -mox-os-font-smoothing: grayscale; // Antialiasing.
    // Change from `box-sizing: content-box` so that `width`
    // is not affected by `padding` or `border`.
    box-sizing: border-box;
    // Fix font resize problem in iOS
    -webkit-text-size-adjust: 100%;
    letter-spacing: 0.00938em;
    line-height: 1.5;
}

:global(#root) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

// MUI default overrides

:global(.MuiInput-root) {
    font-size: 1rem;

    &::after {
        border-bottom-color: colors.$bluePurple !important;
    }

    @include device-mixins.sm {
        font-size: 1.3rem;
    }
}

:global(.MuiFilledInput-root) {
    font-size: 1rem;
    &::after {
        border-bottom-color: colors.$bluePurple !important;
    }

    @include device-mixins.sm {
        font-size: 1.3rem;
    }
}

:global(.MuiOutlinedInput-root) {
    font-size: 1rem;

    @include device-mixins.sm {
        font-size: 1.3rem;
    }
}

:global(.MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline) {
    border-color: colors.$bluePurple;
    border-width: 1px;
}

:global(.MuiInputLabel-root) {
    font-size: 1rem;

    @include device-mixins.sm {
        font-size: 1.3rem;
    }
}

:global(.MuiInputLabel-root.Mui-focused) {
    color: colors.$bluePurple;
    border-color: colors.$bluePurple;
}

:global(.MuiCheckbox-root.Mui-checked) {
    color: colors.$bluePurple;
}

:global(.MuiTextField-root .Mui-focused) {
    border-color: colors.$bluePurple;
}

:global(.MuiRadio-root.Mui-checked) {
    color: colors.$bluePurple;
}

:global(.MuiButtonBase-root) {
    text-transform: none;
}

:global(.MuiSwitch-switchBase.Mui-checked) {
    color: colors.$bluePurple;
}

:global(.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track) {
    background-color: colors.$bluePurple;
}

