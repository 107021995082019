@use '../_colors.scss';
@use '../_device-mixins.scss';


.thumbnail_size {
    width: 90px;
    height: 50.625px;
}

.container {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    @extend .thumbnail_size;
}

.document_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: colors.$lightGray;
    justify-content: center;
}

.img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.skeleton {
    border-radius: 16px;
    background-color: colors.$lightGray;

    @extend .thumbnail_size;
}

.iconButton {
    position: absolute;
    right: 2px;
    bottom: 2px;
    color: black;
    background-color: colors.$lightGray;

    &:hover {
        background-color: colors.$gray;
    }
}

.trash {
    font-size: 16px;
}
