@use '../_colors.scss';
@use '../global.scss';
@use '../_device-mixins.scss';
@use '../_global_vars.scss';

.container {
    overflow: auto;
    height: 100%;
    background-color: colors.$bluePurple;
}

.logo_text_link_link {
    margin: 3% 0;

    @include device-mixins.lg {
        justify-content: flex-start;
        margin: 1% 0 0 1%;
    }
}

.logo_text_link_icon {
    font-size: 200px !important;
    height: auto !important;
    margin: 1.5%;
}

.h1 {
    font-size: 2rem;
}

.p {
    font-size: 1.3rem;
    max-width: 850px;
    margin-bottom: 10px;
}

.card {
    padding: 1.5%;
    width: fit-content;
    margin: auto;
    margin: 1.5%;

    @include device-mixins.md {
        margin: 1.5% auto;
    }
}

.content_container {
    text-align: center;
}
