@use '../colors';
@use '../device-mixins';
@use '../global_vars';

.cta_button {
    cursor: pointer;
    background-color: colors.$lightBluePurple;
    border: none;
    border-radius: 4px;
    padding: 0 1rem;
    font-weight: 700; 
    color: colors.$darkBlue;
}

.progress_bar {
    width: 100%;
    margin-top: 5px;
    height: 3px;
    border-radius: 10px;
    background-color: colors.$lightGray;

    & > span {
        background-color: colors.$bluePurple;
    }
}

.menu {
    margin-top: 10px;
}

.menu_content_container {
    display: flex;
    flex-direction: column;
    padding: 0.6rem 1rem 0 1rem;
}

.h3 {
    margin: 0;
    color: colors.$black;
    font-size: 1.2rem;
}

.h4 {
    margin: 0.5rem 0 0;
    font-weight: 400;
}

.ul {
    padding: 0 1rem 0 1.5rem;
    list-style: none;
}

.li {
    position: relative;
    margin: 10px 0;
}

.x_bullet {
    &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath d='M12.7116 0.298256C12.3271 -0.0862721 11.7059 -0.0862721 11.3214 0.298256L6.5 5.10978L1.67861 0.288396C1.29408 -0.096132 0.672924 -0.096132 0.288396 0.288396C-0.096132 0.672924 -0.096132 1.29408 0.288396 1.67861L5.10978 6.5L0.288396 11.3214C-0.096132 11.7059 -0.096132 12.3271 0.288396 12.7116C0.672924 13.0961 1.29408 13.0961 1.67861 12.7116L6.5 7.89022L11.3214 12.7116C11.7059 13.0961 12.3271 13.0961 12.7116 12.7116C13.0961 12.3271 13.0961 11.7059 12.7116 11.3214L7.89022 6.5L12.7116 1.67861C13.0863 1.30394 13.0863 0.672924 12.7116 0.298256Z' fill='%23D81E5B'/%3E%3C/svg%3E");
        width: 13px;
        height: 13px;
        position: absolute;
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.checkmark_bullet {
    &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='13' viewBox='0 0 18 13' fill='none'%3E%3Cpath d='M5.41909 10.2565L1.37658 6.21402L0 7.58091L5.41909 13L17.0522 1.36689L15.6853 0L5.41909 10.2565Z' fill='%2300BD9D'/%3E%3C/svg%3E");
        width: 17.052px;
        height: 13px;
        position: absolute;
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.upgrade_button {
    margin-right: 1rem;
    color: colors.$bluePurple;
    border: 1px solid colors.$bluePurple;
    background-color: colors.$white;
}

.see_opts_button {
    align-self: center;
    margin: 1rem 0;
}

.resources_text {
    font-size: 0.9rem;
}

.a {
    color: colors.$bluePurple;
    font-weight: 500;
}