@use '../../colors.scss';
@use '../../device-mixins';
@use '../../global_vars';

.menu {
    // outline: none;
    text-decoration: none;
}

.menu_icon {
    color: colors.$black;
    font-size: 1.3rem;
}

.menu_icon_stars {
    font-size: 1.4rem;
    
    & > path {
        fill: colors.$black;
    }
}

.menu_text {
    color: colors.$black;

    & > span {
        font-size: 1.2rem;
    }
}

.menu_sign_out {
    text-transform: none;
}

