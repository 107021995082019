@use '../_colors.scss';
@use '../_device-mixins.scss';

.tooltip {
    font-size: 0.9rem;
    background-color: colors.$darkGray;
}

.icon_button {
    margin-left: -5px;

    .icon {
        font-size: 1.2rem;
        color: colors.$darkGray;
    }
}

.button {
    color: colors.$black;
    padding: 0 20px;
}

