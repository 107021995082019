@use '../../../../colors';
@use '../../../../device-mixins';

.form {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    background-color: colors.$lightBlueGray;
    overflow-x: hidden;

    @include device-mixins.xs {
        background-color: colors.$white;
    }
}

.h1 {
    font-size: 2.5rem;
    margin: 0;
}

.h3 {
    margin-top: 5%;
    margin-bottom: 0;

    @include device-mixins.md {
        margin-top: 1%;
    }
}

.header_card {
    display: grid;
    flex-grow: 1;
}

.header_card_xs {
    @include device-mixins.xs {
        display: none;
    }

    @include device-mixins.sm {
        display: grid;
        flex-grow: 1;
    }
}

.slider_label {
    text-align: center;
}

.slider_button_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    margin-top: 20px;
}

.response_content_container {
    flex: 1 1 auto;
    display: grid;
    grid-template-rows: 1fr auto;
    
    @include device-mixins.sm {
        display: unset;
        grid-template-rows: unset;
        padding-top: 60px;
        position: relative;
        background-color: colors.$lightBlueGray;
        height: 100%;
    
        flex-direction: row-reverse;
    }
}

.two_cols_w_toolbar {
    @include device-mixins.md {
        grid-template-columns: 70px 1fr;
    }
}

.response_input {
    display: flex;
    grid-column: 4 span;

    @include device-mixins.xs {
        grid-row: 2;
        grid-column: 1;
    }

    @include device-mixins.sm {
        grid-row: auto;
        grid-column: 2;
        margin-bottom: 0;
    }
}

.response_input :global .MuiInputBase-root {
    background-color: colors.$white;
    border-radius: 0;
    padding-top: 30px;
    padding-bottom: 120px;

    @include device-mixins.sm {
        padding: 20px 20px 120px 20px;
        border-radius: 8px;
        border-width: 1px;
        border-color: #E2E2E2;
    }
}

.response_input_container {
    display: grid;
    height: auto;
    position: relative;

    @include device-mixins.xs {
        grid-template-rows: max-content;
        grid-column: span 3;
    }

    @include device-mixins.sm {
        height: 85%;
        min-height: 300px;
        grid-template-rows: unset;
    }
}

.response_content_grid {
    @include device-mixins.xs {
        grid-template-columns: 1fr;
        transition: grid-template-columns 0.35s;
    }

    @include device-mixins.sm {
        grid-template-columns: 60px .60fr .40fr 60px;
    }
}

.response_scaffold_grid {
    @include device-mixins.sm {
        grid-template-columns: 30px 1fr 180px 30px;
        transition: grid-template-columns 0.35s;
    }

    @include device-mixins.md {
        grid-template-columns: 60px 1fr 180px 60px;
    }
}

.response_input_grid {
    @include device-mixins.sm {
        grid-template-columns: 60px 1fr 0fr 60px;
    }
}

.copy_paste_alert {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 4px;
}

.error_text {
    color: colors.$red;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1rem;
    padding-left: 10px;
    grid-column: 3;

    @include device-mixins.xs {
        background-color: colors.$white;
    }

    @include device-mixins.sm {
        background-color: colors.$lightBlueGray;
    }

    @include device-mixins.md {
        padding-left: 60px;
    }
}

.error_text_hidden {
    visibility: hidden;
}

.scaffolds_container {
    grid-column: 3;
    padding-left: 20px;
}

.scaffold_header {
    font-weight: bold;
    margin: 0;
}

.loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_area_next {
    & > div > div > button {
        display: none;

        @include device-mixins.sm {
            display: flex;
        }
    }
}

.number_slider_container {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.button_container {
    margin-top: 40px;
}

.text_button {
    color: colors.$darkBluePurple;
    margin-right: 50px;
}

.error {
    color: colors.$red;
    font-size: 1rem;
    margin-top: 1rem;
}