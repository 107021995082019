@use '../colors';
@use '../device-mixins';

.menu_item_icon {
    color: colors.$black;
    font-size: 1rem;
}

.menu_item_text {
    font-size: 1rem;
}

.vertical_three_dot {
    position: absolute;
    top: 5px;
    right: 0;

    & > svg {
        color: colors.$black;
    }
}

.topic_three_dot {
    color: colors.$black;

    svg {
        font-size: 11px;
    }
}

.rename_topic_input {
    width: 500px;
    max-width: 100%;
}

.delete_topic_content {
    max-width: 375px;
    font-size: 1.1rem;
}