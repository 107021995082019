@use '../../colors';
@use '../../device-mixins';

.dialog_close_button {
    color: colors.$black;
    display: none;
}

.dialog_content {
    background-color: colors.$white;
    padding: 0;
    overflow-x: hidden;
}

.form_header_container {
    padding: 20px 20px 0;
}

.back_button {
    margin: 10px 0;
    padding: 0;
    min-width: initial;
    color: colors.$gray;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
}

.back_arrow_icon {
    margin-right: 8px;
    font-size: 1rem;
    color: colors.$gray;
}

.hide {
    display: none;
}

.header_container {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: 0 0 10px 0;
    gap: 100px;
}

.title_container {
    width: 575px;
    overflow: hidden;
}

.h1 {
    color: colors.$darkBlue;
    font-weight: 900;
    font-size: 32px;
    margin: 0 10px;
}

.activity_settings_h1 {
    font-size: 36px;
    margin: 0 0 10px 0;
}

.h2 {
    color: colors.$darkBlue;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0;

    @include device-mixins.sm {
        font-size: 1.5rem;
    }
}

.caption {
    color: colors.$darkBlue;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

    & > span {
        color: colors.$bluePurple;
        font-weight: 900;
    }
}

.start_button_container {
    position: relative;
}

.right_arrow_icon {
    font-size: 16px;
}

.button {
    border-radius: 6px;
    font-size: 16px;
    padding: 12px 42px;
    flex: 1;
}

.tabs{
    :global(.MuiTabs-indicator) {
        width: 2px;
        background-color: colors.$bluePurple;
    }

    .tab {
        display: flow;
        padding: 0;
        margin: 0 10px;
        color: colors.$darkBlue;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;

        &:global(.Mui-selected) {
            color: colors.$bluePurple;
            font-weight: 700;
        }

        :global(.MuiChip-root) {
            margin-bottom: 20px;
            border-radius: 5px;
            background: colors.$seafoam;
            width: 40px;
            height: 25px;
            padding: 5px;

            color: colors.$white;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;

            :global(.MuiChip-label) {
                overflow: initial;
                text-overflow: initial;
                padding: 0;
                white-space: initial;
            }
        }
        
    }
}

.activity_radio_card_container {
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
    width: 100%;
    padding: 10px;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 10px;
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: colors.$black;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.activity_card_radio {
    scroll-snap-align: center;
    flex: 0 0 auto;
    position: relative;
}

.quick_write_note {
    padding-left: 2rem;
    & > a {
        color: colors.$black;
    }
}