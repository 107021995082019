@use '../../../colors';
@use '../../../device-mixins';

.form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: colors.$bluePurple;
}

.card {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 3%;
}

.h1 {
    font-size: 1.7rem;
    color: colors.$darkBlue;
    margin-top: 0;

    @include device-mixins.sm {
        font-size: 2rem;
    }
}

.error_text {
    max-width: 300px;
    font-size: 0.9rem;

    @include device-mixins.sm {
        max-width: 400px;
        font-size: 1rem;
    }
}
